import PropTypes from 'prop-types';

export default function Visible({ children, visible, hidden }) {
  if (!visible || hidden) {
    return null;
  }

  return children;
}

Visible.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  hidden: PropTypes.bool,
};

Visible.defaultProps = {
  children: null,
  visible: true,
  hidden: false,
};
