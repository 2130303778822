/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import './styles.css';

export default function CookieInformation({ children, culture }) {
  return (
    <>
      <Helmet>
        <script
          id="CookieConsent"
          src="https://policy.app.cookieinformation.com/uc.js"
          data-culture={culture.toUpperCase()}
          type="text/javascript"
          data-gcm-enabled="false"
          async
        />
      </Helmet>
      {children}
    </>
  );
}

CookieInformation.propTypes = {
  children: PropTypes.node,
  culture: PropTypes.string,
};

CookieInformation.defaultProps = {
  children: null,
  culture: 'EN',
};
