import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link rel="preload" as="style" href="https://use.typekit.net/fkk1vht.css" />
      <link rel="stylesheet" href="https://use.typekit.net/fkk1vht.css" />
    </Helmet>
  );
}

export default DefaultFonts;
