import React from 'react';

import { Helmet } from 'react-helmet';

function MetaDomainVerificationTag() {
  if (!process.env.GATSBY_META_DOMAIN_VERIFICATION_CODE) {
    return null;
  }

  return (
    <Helmet>
      <meta name="facebook-domain-verification" content={process.env.GATSBY_META_DOMAIN_VERIFICATION_CODE} />
    </Helmet>
  );
}

export default MetaDomainVerificationTag;
