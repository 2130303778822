import '../../fonts/koskenkorva/fontface.css';

const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(200, 49, 69)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  primaryColor: '#222222',
  golden: '#d68d00',
  brown: '#51332a',
};

const textColors = {
  bodyText: '#333333',
  headingColor: '#333333',
  mutedText: '#625856',
  disabledText: '#B9B9B9',
  lightText: colors.white,
};
const textFontFamilies = {
  fontTypography: '"Archer-Medium", sans-serif',
  fontBody1: '"Archer-Medium", sans-serif',
  fontBody2: '"Archer-Medium", sans-serif',
  fontHeadings: '"HeroicCondensed-Bold", serif',
  fontBotton: '"Archer-Medium", serif',
};
const textFontSizesMobile = {
  fontSizeLeadText: '1.125rem',
  fontSizeTypography: '0.938rem',
  fontSizeButton: '0.875rem',
  fontSizeBody1: '0.938rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2.5rem',
  fontSizeH2: '1.75rem',
  fontSizeH3: '1.188rem',
  fontSizeH4: '1.125rem',
};
const textFontSizesDesktop = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '1rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '3.375rem',
  fontSizeH2: '2.5rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};

const textFontWeight = {
  typography: '500',
  button: '600',
  body1: '500',
  body2: '500',
  h1: 'bold',
  h2: 'bold',
  h3: 'bold',
  h4: 'bold',
};

const textLineHeightsMobile = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.54',
  lineHeightHeadings: '1.1',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.58',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.54',
  lineHeightHeadings: '1.11',
};

const typography = {
  h1: {
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'uppercase',
  },
  h3: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h4: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h5: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h6: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#ab6700',
  linkHover: '#773f0c',
  linkPressed: '#773f0c',
};

const iconColors = {
  iconColor: '#333333',
};
const hr = {
  backgroundColor: '#dcca9b',
};

const backgroundColors = {
  paper: colors.white,
  grayLight: '#f0f0f0',
  grayDark: '#373737',
  lightBeige: '#fffdee',
  darkBrown: '#51332a',
  overlay: 'rgba(51,51,51,0.5)',
};

const formColors = {
  inputBorder: '#dcca9b',
  inputBackground: '#fffdee',
  inputActiveBackground: '#fffdee',
  inputActiveBorder: '#d68d00',
  inputBorderRadius: '0',
};

const primaryButtonColors = {
  primaryButtonBackground: '#ab6700',
  primaryButtonBackgroundGradient: 'none',
  primaryButtonHoveredBackground: '#773f0c',
  primaryButtonPressedBackground: '#773f0c',
  primaryButtonDisabledBackground: 'rgba(169,150,141,0.4)',
  primaryButtonContents: '#FFFFFF',
};
const secondaryButtonColors = {
  secondaryButtonBackground: '#f0f0f0',
  secondaryButtonHoveredBackground: '#dfdfdf',
  secondaryButtonPressedBackground: '#c2c2c2',
  secondaryButtonDisabledBackground: '#f0f0f0',
  secondaryButtonContents: '#FFFFFF',
};

const outlinedButtonColors = {
  outlinedButton: '#ab6700',
  outlinedButtonHovered: '#773f0c',
  outlinedButtonPressed: '#773f0c',
  outlinedButtonDisabled: '#a0968d',
  outlinedButtonHoveredBackground: 'transparent',
};

const buttonSettings = {
  buttonPaddingDesktop: '12px 14px 12px 14px',
  buttonPaddingMobile: '8px 10px 8px 10px',
  borderRadius: '9px',
  textTransform: 'none',
};

const notificationColors = {
  infoBackground: '#ecf1f8',
  successBackground: '#f9ffeb',
  warningBackground: '#fef3e8',
  errorBackground: '#fde9e9',

  infoContents: '#3d68a8',
  successContents: '#7fa728',
  warningContents: '#e8770e',
  errorContents: '#d71717',

  borderRadius: '8px',
};

const header = {
  text: brandColors.primaryColor,
  background: 'transparent',
  minHeight: '120px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: linkColors.link,
    activeLinkBorder: 'none',
    hoverText: linkColors.linkHover,
    subMenuBackground: '#fffdee',
    subMenuBulletPoints: brandColors.golden,
  },
  border: {
    style: 'none',
    bottomWidth: '0',
  },
  icons: {
    color: textColors.bodyText,
    backgroundColor: 'transparent',
  },
};

const footer = {
  border: {
    color: brandColors.brown,
    width: '3px',
  },
  divider: brandColors.brown,
  text: colors.white,
  background: brandColors.brown,
  logoBarBackground: brandColors.brown,
  padding: {
    bottom: '50px',
  },
};

const accordion = {
  backgroundColor: backgroundColors.grayLight,
  borderRadius: '12px',
  color: linkColors.link,
  titleFontSize: '1.125rem',
  hover: {
    backgroundColor: '#ffffff',
    color: linkColors.linkHover,
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
    },
  },
  padding: {
    left: '16px',
    right: '12px',
  },
};

const languageSelector = {
  color: '#333333',
  border: {
    color: formColors.inputBorder,
    width: '2px',
  },
  margin: '0',
};

const linkBlockModule = {
  center: {
    textAlign: 'center',
    '& > p, h1, h2, h3, h4, h5, a, div': {
      textAlign: 'center',
    },
  },
};

const table = {
  border: `solid 1px ${brandColors.primaryColor}`,
  borderRadius: '12px',
  head: {
    backgroundColor: brandColors.primaryColor,
    color: colors.white,
  },
  row: {
    even: {
      backgroundColor: colors.white,
    },
  },
  cell: {
    border: `solid 1px ${formColors.inputBorder}`,
  },
};

const category = {
  outlined: {
    background: brandColors.primaryColor,
    text: colors.white,
  },
  text: linkColors.link,
  hoverText: linkColors.linkHover,
};

const mobileNavigation = {
  text: colors.white,
  icon: colors.white,
  xIconBackground: primaryButtonColors.primaryButtonBackground,
  xIconBackgroundHover: primaryButtonColors.primaryButtonHoveredBackground,
  logoBarBottomBorder: 'transparent',
  navigationElementBorder: brandColors.golden,
  subNavigationTitleBackground: brandColors.primaryColor,
  subNavigationTitleColor: colors.white,
  drawer: {
    paper: {
      backgroundColors: brandColors.primaryColor,
      widthDesktop: '350px',
    },
  },
};

const imageSpotLink = {
  innerDot: {
    color: brandColors.golden,
  },
};
const slider = {
  arrowBotton: {
    borderRadius: '50%',
  },
};

export const koskenkorvaPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  typography,
  pageTemplates,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  languageSelector,
  linkBlockModule,
  table,
  category,
  mobileNavigation,
  hr,
  imageSpotLink,
  slider,
};
