import '../../fonts/bibendum/MyFontsWebfontsKit.css';

const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(200, 49, 69)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  forest: '#2A5135',
  petrolium: '#034244',
  fire: '#FF4713',
  pink: '#E88697',
  lightpink: '#d4817d',
};

const textColors = {
  bodyText: '#333333',
  headingColor: '#333333',
  mutedText: '#7D7D7D',
  disabledText: '#B9B9B9',
  lightText: colors.white,
};
const textFontFamilies = {
  fontTypography: '"AlmarenaLight", sans-serif',
  fontBody1: '"AlmarenaLight", sans-serif',
  fontBody2: '"AlmarenaLight", sans-serif',
  fontHeadings: '"AlmarenaLight", serif',
  fontBotton: 'AlmarenaLight, sans-serif',
};
const textFontSizesMobile = {
  fontSizeLeadText: '1.125rem',
  fontSizeTypography: '0.938rem',
  fontSizeButton: '0.875rem',
  fontSizeBody1: '0.938rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2rem',
  fontSizeH2: '1.5rem',
  fontSizeH3: '1.188rem',
  fontSizeH4: '1.125rem',
};
const textFontSizesDesktop = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '1rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '3.375rem',
  fontSizeH2: '2.25rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};

const textFontWeight = {
  typography: 'normal',
  button: '600',
  body1: 'normal',
  body2: 'normal',
  h1: '500',
  h2: '500',
  h3: '500',
  h4: '500',
};

const textLineHeightsMobile = {
  lineHeightLeadText: '1.58',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const typography = {
  h1: {
    marginBottom: '2.5rem',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'none',
  },
  h3: {
    marginBottom: '1rem',
  },
  h4: {
    marginBottom: '1rem',
  },
  h5: {
    marginBottom: '1rem',
  },
  h6: {
    marginBottom: '1rem',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 5, // => value x 8px
    },
    desktop: {
      paddingTop: 7, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 5, // => value x 8px
    },
    desktop: {
      paddingTop: 7, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#2A5135',
  linkHover: '#14341D',
  linkPressed: '#031B0A',
};

const iconColors = {
  iconColor: '#333333',
};
const hr = {};

const backgroundColors = {
  paper: colors.white,
  grayLight: '#F0F0F0F0',
  grayMid: '#A9AEB2',
  grayDark: '#373737',
  overlay: 'rgba(51,51,51,0.5)',
};

const formColors = {
  inputBorder: '#B4B4B4',
  inputBackground: '#F0F0F0',
  inputActiveBackground: '#F0F0F0',
  inputActiveBorder: '#034244',
  inputBorderRadius: '0',
};

const primaryButtonColors = {
  primaryButtonBackground: '#2A5135',
  primaryButtonBackgroundGradient: 'none',
  primaryButtonHoveredBackground: '#14341D',
  primaryButtonPressedBackground: '#031B0A',
  primaryButtonDisabledBackground: 'rgba(20,52,29,0.4)',
  primaryButtonContents: '#FFFFFF',
};
const secondaryButtonColors = {
  secondaryButtonBackground: '#2A5135',
  secondaryButtonHoveredBackground: '#14341D',
  secondaryButtonPressedBackground: '#031B0A',
  secondaryButtonDisabledBackground: 'rgba(20,52,29,0.4)',
  secondaryButtonContents: '#FFFFFF',
};

const outlinedButtonColors = {
  outlinedButton: '#034244',
  outlinedButtonHovered: '#fff',
  outlinedButtonPressed: '#011C1D',
  outlinedButtonDisabled: 'rgba(2,48,50,0.4)',
  outlinedButtonHoveredBackground: '#034244',
};

const buttonSettings = {
  buttonPaddingDesktop: '12px 18px 12px 18px',
  buttonPaddingMobile: '10px 14px 10px 14px',
  borderRadius: '0',
  textTransform: 'inherit',
};

const notificationColors = {
  infoBackground: '#E2F1FF',
  successBackground: '#E9FAEE',
  warningBackground: '#FFF1E0',
  errorBackground: '#FFECF4',

  infoContents: '#4F8BC2',
  successContents: '#236637',
  warningContents: '#BD7D2E',
  errorContents: '#BD175D',
};

const header = {
  text: colors.forest,
  background: colors.white,
  minHeight: '72px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: textColors.bodyText,
    activeLinkBorder: 'none',
    hoverText: colors.pink,
    subMenuBackground: colors.white,
    subMenuBulletPoints: colors.white,
  },
  border: {
    style: 'solid',
    bottomWidth: '3px',
  },
  icons: {
    color: colors.white,
    backgroundColor: colors.black,
  },
};

const footer = {
  border: {
    color: colors.darkerBrown,
    width: '3px',
  },
  divider: colors.darkerBrown,
  text: colors.white,
  background: colors.darkerBrown,
  logoBarBackground: colors.lightGray,
  padding: {
    bottom: '50px',
  },
};

const accordion = {
  backgroundColor: brandColors.forest,
  borderRadius: 0,
  color: colors.white,
  titleFontSize: '1.125rem',
  hover: {
    backgroundColor: brandColors.forest,
    color: colors.white,
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
    },
  },
  padding: {
    left: '16px',
    right: '12px',
  },
};

const languageSelector = {
  border: {
    color: formColors.inputBorder,
    width: '2px',
  },
  margin: '0',
};

const table = {
  border: `solid 1px ${brandColors.forest}`,
  borderRadius: 0,
  head: {
    backgroundColor: brandColors.forest,
    color: colors.white,
  },
  row: {
    even: {
      backgroundColor: colors.white,
    },
  },
  cell: {
    border: `solid 1px ${brandColors.forest}`,
  },
};

const category = {
  outlined: {
    background: brandColors.forest,
    text: colors.white,
  },
  text: brandColors.forest,
  hoverText: brandColors.lightpink,
};

const mobileNavigation = {
  text: colors.darkBrown,
  icon: colors.darkBrown,
  xIconBackground: colors.lightBrown,
  logoBarBottomBorder: colors.darkBlue,
  navigationElementBorder: colors.lightGray,
  subNavigationTitleBackground: colors.lightGreen,
  subNavigationTitleColor: colors.darkBlue,
  drawer: {
    paper: {
      backgroundColors: backgroundColors.paper,
      widthDesktop: '350px',
    },
  },
};

export const bibendumPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  typography,
  pageTemplates,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  languageSelector,
  table,
  category,
  mobileNavigation,
  hr,
};
