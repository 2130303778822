import React from 'react';

import { Helmet } from 'react-helmet';
import { isSSR } from '../../../../utils/windowUtils';

function FrosmoScript() {
  if (isSSR()) {
    return null;
  }
  return (
    <Helmet>
      <script type="text/javascript" charset="utf-8" src="//d2wzl9lnvjz3bh.cloudfront.net/frosmo.easy.js" async />
      <script
        type="text/javascript"
        charset="utf-8"
        src="//d2wzl9lnvjz3bh.cloudfront.net/sites/folkofolk_se.js"
        async
      />
    </Helmet>
  );
}

FrosmoScript.propTypes = {};

FrosmoScript.defaultProps = {};

export default FrosmoScript;
