const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(242, 242, 242)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(200, 49, 69)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(199,161,122)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  primaryColor: '#9D5812',
  secondaryColor: '#715E4B',
};

const textColors = {
  bodyText: colors.black,
  mutedText: '#7D7D7D',
  disabledText: '#B9B9B9',
  lightText: colors.white,
  highlightedText: colors.lightBrown,
};
const textFontFamilies = {
  fontTypography: "'Josefin Sans', sans-serif",
  fontBody1: "'Josefin Sans', sans-serif",
  fontBody2: "'Josefin Sans', sans-serif",
  fontHeadings: "'Playfair Display', sans-serif",
  h1: "'Playfair Display', sans-serif",
  h2: "'Playfair Display', sans-serif",
  h3: "'Josefin Sans', sans-serif",
  h4: "'Josefin Sans', sans-serif",
  h5: "'Josefin Sans', sans-serif",
  h6: "'Josefin Sans', sans-serif",
  fontButton: "'Josefin Sans', sans-serif",
};

const textFontSizesMobile = {
  fontSizeLeadText: '1.125rem',
  fontSizeTypography: '0.938rem',
  fontSizeButton: '0.875rem',
  fontSizeBody1: '0.938rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '1.75rem',
  fontSizeH2: '1.35rem',
  fontSizeH3: '1.25rem',
  fontSizeH4: '1rem',
};
const textFontSizesDesktop = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '1rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2.5rem',
  fontSizeH2: '2rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};
const textFontWeight = {
  typography: 'normal',
  button: '600',
  body1: 'normal',
  body2: 'normal',
  h1: '700',
  h2: '700',
  h3: '600',
  h4: '600',
};
const textLineHeightsMobile = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.47',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
  h1: 'normal',
  h2: 'normal',
  h3: '1.33',
  h4: '1.25',
  h5: '1.2',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.67',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.54',
  lineHeightHeadings: '1.2',
  h1: '0.926',
  h2: '1.2',
  h3: '1.2',
  h4: '1.2',
};

const textMargins = {
  textMarginBottomH1: '2.5rem',
};
const typography = {
  h1: {
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'uppercase',
  },
  h3: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h4: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h5: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  h6: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 0, // => value x 8px
    },
    desktop: {
      paddingTop: 0, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 7, // => value x 8px
    },
    desktop: {
      paddingTop: 7, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#07789A',
  linkHover: '#095066',
  linkPressed: '#095066',
};

const iconColors = {
  iconColor: '#000000',
};
const hr = {
  color: '#715E4B',
  backgroundColor: '#715E4B',
};

const backgroundColors = {
  paper: '#FFFFFF',
  grayLight: '#f0f0f0',
  grayDark: '#373737',
  beigeLight: '#F6F2EE',
  overlay: 'rgba(51,51,51,0.5)',
  highlight: '#F6F2EE',
};

const formColors = {
  inputBorder: '#B4B4B4',
  inputBackground: '#F0F0F0',
  inputActiveBackground: '#F0F0F0',
  inputActiveBorder: '#373737',
  inputBorderRadius: '0',
};

const primaryButtonColors = {
  primaryButtonBackground: brandColors.primaryColor,
  primaryButtonHoveredBackground: brandColors.secondaryColor,
  primaryButtonPressedBackground: brandColors.secondaryColor,
  primaryButtonDisabledBackground: '#B9B9B9',
  primaryButtonContents: '#FBECDD',

  primaryButtonBorder: brandColors.primaryColor,
  primaryButtonHoverContents: '#FBECDD',
  primaryButtonHoverBorder: brandColors.secondaryColor,
};

const secondaryButtonColors = {
  secondaryButtonBackground: brandColors.primaryColor,
  secondaryButtonHoveredBackground: brandColors.secondaryColor,
  secondaryButtonPressedBackground: brandColors.secondaryColor,
  secondaryButtonDisabledBackground: '#B9B9B9',
  secondaryButtonContents: '#FBECDD',
};

const outlinedButtonColors = {
  outlinedButton: brandColors.primaryColor,
  outlinedButtonHovered: brandColors.secondaryColor,
  outlinedButtonHoveredBackground: 'transparent',
  outlinedButtonPressed: brandColors.secondaryColor,
  outlinedButtonDisabled: '#B9B9B9',
};

const buttonSettings = {
  buttonPaddingDesktop: '8px 10px',
  buttonPaddingMobile: '12px 16px',
  borderRadius: '4px',
  textTransform: 'none',
  hover: {
    shadow: 'none',
  },
};

const notificationColors = {
  infoBackground: '#ecf1f8',
  successBackground: '#e6f9e8',
  warningBackground: '#fef3e8',
  errorBackground: '#fde9e9',

  infoContents: '#3d68a8',
  successContents: '#218e2c',
  warningContents: '#e8770e',
  errorContents: '#d71717',
};

const header = {
  color: colors.white,
  text: colors.white,
  background: '#30271C',
  minHeight: '56px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: linkColors.link,
    activeLinkBorder: 'none',
    hoverText: linkColors.linkHover,
    subMenuBackground: '#30271C',
    subMenuBulletPoints: colors.white,
  },
  icons: {
    color: colors.black,
    backgroundColor: 'transparent',
  },
};

const footer = {
  border: {
    color: 'transparent',
    width: 0,
  },
  divider: colors.white,
  text: textColors.lightText,
  background: '#212121',
  logoBarBackground: '#212121',
  padding: {
    bottom: 0,
  },
};

const accordion = {
  backgroundColor: colors.white,
  borderRadius: '4px',
  color: '#0E7FA1',
  flexDirection: 'row',
  border: 'none',
  hover: {
    border: `none`,
    color: '#333333',
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
      color: linkColors.linkHover,
    },
  },
  padding: {
    left: '24px',
    right: '12px',
  },
  subtitle: {
    color: '#797672',
  },
  startIcon: {
    color: '#333333',
    '&:hover': {
      color: 'inherit',
    },
  },
};
const event = {
  root: {
    padding: '12px 12px 12px 24px',
    borderRadius: '4px',
    marginBottom: '16px',
    backgroundColor: colors.white,
  },
  day: {
    fontFamily: textFontFamilies.fontHeadings,
    fontSize: '28px',
    color: textColors.bodyText,
    fontWeight: '700',
    marginTop: '-16px',
  },
  month: {
    fontSize: '13px',
    color: '#9D5812',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: '18px',
  },
  name: {
    color: linkColors.link,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  shortDescription: {
    color: '#797672',
    fontSize: '15px',
  },
  icon: {
    color: linkColors.link,
    fontSize: '2rem',
  },
};

const languageSelector = {
  color: 'white',
  border: {
    color: colors.white,
    width: '2px',
  },
  margin: '0',
};

const table = {
  border: `solid 1px ${colors.lightBrown}`,
  borderRadius: '4px',
  head: {
    backgroundColor: 'transparent',
  },
  row: {
    even: {
      backgroundColor: 'transparent',
    },
  },
  cell: {
    border: `solid 1px ${colors.lightBrown}`,
  },
};

const category = {
  outlined: {
    background: backgroundColors.grayLight,
    text: '#9D5812',
  },
  text: '#9D5812',
  hoverText: '#9D5812',
};

const mobileNavigation = {
  text: colors.white,
  icon: colors.white,
  xIconBackground: '#30271C',
  logoBarBottomBorder: '#30271C',
  navigationElementBorder: 'transparent',
  subNavigationTitleBackground: brandColors.primaryColor,
  subNavigationTitleColor: brandColors.secondaryColor,
  drawer: {
    paper: {
      backgroundColors: '#30271C',
      widthDesktop: '340px',
    },
  },
};

const slider = {
  arrowBotton: {
    padding: '8px 10px 8px 10px',
  },
};

export const SocialwinesPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  textMargins,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  event,
  table,
  category,
  mobileNavigation,
  hr,
  typography,
  pageTemplates,
  languageSelector,
  slider,
};
