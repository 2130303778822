/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import CookieInformation from './CookieInformation';
import getLanguageFromPathName from './utils';

export const wrapPageElement = ({ element, props }, pluginOptions) => {
  if (process.env.NODE_ENV === 'production' || pluginOptions.includeInDevelopment) {
    const { location } = props;
    const { pathname } = location;
    const { defaultLanguage } = pluginOptions;
    return (
      <CookieInformation culture={getLanguageFromPathName(pathname, defaultLanguage)}>{element}</CookieInformation>
    );
  }
  return element;
};
