import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { get, isEqual, isNil } from 'lodash';

const DialogContext = createContext({
  dialogData: null,
  openDialog: () => {},
  closeDialog: () => {},
  isDialogOpen: () => {},
});

const initialState = { name: null, data: null };

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'openDialog': {
      const name = get(action, 'payload.name');
      const data = get(action, 'payload.data');

      return { ...state, name, data };
    }
    case 'closeDialog': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function DialogProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const openDialog = (name, data) => {
      dispatch({ type: 'openDialog', payload: { name, data } });
    };

    const closeDialog = () => {
      dispatch({ type: 'closeDialog', payload: {} });
    };

    const isDialogOpen = name => {
      return isEqual(name, get(state, 'name'));
    };

    return {
      dialogData: get(state, 'data'),
      openDialog,
      closeDialog,
      isDialogOpen,
    };
  }, [state]);

  return <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>;
}

DialogProvider.propTypes = {
  children: PropTypes.node,
};

DialogProvider.defaultProps = {
  children: null,
};

export function useDialog() {
  const context = useContext(DialogContext);
  if (isNil(context)) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
}
