import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet" />
      <link rel="preload" as="style" href="https://use.typekit.net/mqe7api.css" />
      <link rel="stylesheet" href="https://use.typekit.net/mqe7api.css" />
      <link
        as="font"
        crossOrigin="anonymous"
        href="../../../../fonts/Gotham/Gotham-Bold.otf"
        rel="preload"
        type="font/opentype"
      />
      <link
        as="font"
        crossOrigin="anonymous"
        href="../../../../fonts/Gotham/Gotham-Book.otf"
        rel="preload"
        type="font/opentype"
      />
      <link
        as="font"
        crossOrigin="anonymous"
        href="../../../../fonts/Gotham/Gotham-Medium.otf"
        rel="preload"
        type="font/opentype"
      />
    </Helmet>
  );
}

export default DefaultFonts;
