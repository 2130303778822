/**
 * Returns language from path by looking from the beginning of the string
 * @param {string} pathName path of the page
 * @param {string} defaultLanguage default language to use if path is invalid (defaults to 'en')
 * @returns language found form path name
 */
export default function getLanguageFromPathName(pathName, defaultLanguage = 'en') {
  if (!pathName || typeof pathName !== 'string' || pathName.length === 0) {
    return defaultLanguage;
  }
  const startIndex = pathName.indexOf('/');
  if (startIndex === -1) {
    return defaultLanguage;
  }
  let endIndex = pathName.indexOf('/', startIndex + 1);
  if (endIndex === -1) {
    endIndex = pathName.length;
  }
  const lang = pathName.slice(startIndex + 1, endIndex);
  if (lang.length !== 2) {
    // Language code must be 2 letter code
    return defaultLanguage;
  }
  return lang;
}
