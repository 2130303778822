import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { isNil, uniqueId } from 'lodash';

import get from 'lodash/get';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

import { messageTypes } from '../../constants';

const MessageContext = createContext({
  messages: [],
  removeMessage: () => {},
  createErrorMessage: () => {},
  createInfoMessage: () => {},
  createWarningMessage: () => {},
  createSuccessMessage: () => {},
});

const initialState = [];

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'createMessage': {
      return concat([], state, {
        id: get(action, 'payload.id'),
        type: get(action, 'payload.type'),
        text: get(action, 'payload.text'),
        options: get(action, 'payload.options'),
        route: get(action, 'payload.route'),
      });
    }
    case 'removeMessage': {
      return filter(state, message => !isEqual(get(message, 'id'), get(action, 'payload.id')));
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function MessageProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const removeMessage = id => {
      dispatch({
        type: 'removeMessage',
        payload: { id },
      });
    };

    const createErrorMessage = (text, options, route) => {
      dispatch({
        type: 'createMessage',
        payload: { id: uniqueId(messageTypes.Error), type: messageTypes.Error, text, options, route },
      });
    };

    const createInfoMessage = (text, options, route) => {
      dispatch({
        type: 'createMessage',
        payload: { id: uniqueId(messageTypes.Info), type: messageTypes.Info, text, options, route },
      });
    };

    const createWarningMessage = (text, options, route) => {
      dispatch({
        type: 'createMessage',
        payload: { id: uniqueId(messageTypes.Warning), type: messageTypes.Warning, text, options, route },
      });
    };

    const createSuccessMessage = (text, options, route) => {
      dispatch({
        type: 'createMessage',
        payload: { id: uniqueId(messageTypes.Success), type: messageTypes.Success, text, options, route },
      });
    };

    return {
      messages: state,
      removeMessage,
      createErrorMessage,
      createInfoMessage,
      createWarningMessage,
      createSuccessMessage,
    };
  }, [state]);

  return <MessageContext.Provider value={contextValue}>{children}</MessageContext.Provider>;
}

MessageProvider.propTypes = {
  children: PropTypes.any,
};

MessageProvider.defaultProps = {
  children: null,
};

export function useMessage() {
  const context = useContext(MessageContext);
  if (isNil(context)) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
}
