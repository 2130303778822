import { KlevuConfig } from '@klevu/core';

KlevuConfig.init({
  url: process.env.GATSBY_KlEVU_URL_SEARCH,
  apiKey: process.env.GATSBY_KlEVU_API_KEY,
});

export default function KlevuInit() {
  return null;
}
