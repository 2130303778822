import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, map, filter, find, isEqual } from 'lodash';
import {
  ButtonBase,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  withStyles,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import Visible from '../../../shared/Visible';
import Link from '../../Link';

const styles = theme => ({
  root: {
    padding: '0',
  },
  subNavigationTitleBar: {
    display: 'block',
    minHeight: '56px',
    backgroundColor: theme.palette.mobileNavigation.subNavigationTitleBackground,
    padding: '12px 12px 12px 20px',
  },
  subNavigationTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  subNavigationTitle: {
    color: theme.palette.mobileNavigation.subNavigationTitleColor,
    fontSize: '22px',
    fontWeight: 'bold',
  },
  BackIconWrapper: { padding: '8px' },
  iconWrapper: { padding: '8px' },
  icon: { fontSize: '1.3rem', color: theme.palette.mobileNavigation.icon, margin: '4px' },
  label: {
    color: theme.palette.mobileNavigation.text,
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  listItem: {
    padding: '0 ',
    borderBottom: `1px solid ${theme.palette.mobileNavigation.navigationElementBorder}`,
  },
  listItemTextRight: {
    flexGrow: '1',
    marginRight: '48px',
  },
  buttonBase: { height: '56px' },

  listItemTextLeft: {
    flexGrow: '1',
    padding: ' 0  0 0 10px',
  },
  link: {
    color: theme.palette.mobileNavigation.text,
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    padding: '12px 12px 12px 20px',
    textTransform: 'uppercase',
  },
  activeLink: {
    color: theme.palette.header.navigation.activeLink,
  },
  secondaryActionRight: {
    right: '0',
  },
  listItemBottom: {},
});

function getActiveNavigationElement(navigationElements, pathname) {
  return find(navigationElements, {
    pageReference: {
      fullPath: pathname,
    },
  });
}

function getFilteredNavigationElements(navigationElements, parentNavigationElement) {
  return filter(navigationElements, navigationElement => {
    return isEqual(get(navigationElement, 'parentNavigationElement.id'), get(parentNavigationElement, 'id'));
  });
}

function getNavigationElement(navigationElements, navigationElementId) {
  const navigationElement = find(navigationElements, {
    id: navigationElementId,
  });

  return isEmpty(navigationElement) ? null : navigationElement;
}

function NavigationElements({ classes, className, data: navigationElements, onNavigationElementClick }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [parentNavigationElement, setParentNavigationElement] = useState(null);

  const activeNavigationElement = useMemo(() => {
    return getActiveNavigationElement(navigationElements, pathname);
  }, [navigationElements, pathname]);

  const filteredNavigationElements = useMemo(() => {
    return getFilteredNavigationElements(navigationElements, parentNavigationElement);
  }, [navigationElements, parentNavigationElement]);

  const handleBackClick = useCallback(() => {
    const navigationElementId = get(parentNavigationElement, 'parentNavigationElement.id');
    const navigationElement = getNavigationElement(navigationElements, navigationElementId);
    setParentNavigationElement(navigationElement);
  }, [navigationElements, parentNavigationElement]);

  const handleIconClick = useCallback(
    navigationElement => () => {
      setParentNavigationElement(navigationElement);
    },
    [],
  );

  const handleLinkClick = useCallback(
    navigationElement => () => {
      onNavigationElementClick(navigationElement);
    },
    [onNavigationElementClick],
  );

  useEffect(() => {
    const navigationElementId = get(activeNavigationElement, 'parentNavigationElement.id');
    const navigationElement = getNavigationElement(navigationElements, navigationElementId);
    setParentNavigationElement(navigationElement);
  }, [navigationElements, activeNavigationElement]);

  const parentPage = {
    fullPath: get(parentNavigationElement, 'pageReference.fullPath'),
    title: get(parentNavigationElement, 'pageReference.title'),
  };

  if (isEmpty(parentPage.title) && !isEmpty(parentNavigationElement)) {
    parentPage.title = get(parentNavigationElement, 'title');
  }

  return (
    <List component="nav" className={clsx(classes.root, className)}>
      <Visible hidden={isEmpty(parentNavigationElement)}>
        <ListItem className={classes.subNavigationTitleBar}>
          <Link
            to={parentPage.fullPath}
            onClick={handleLinkClick(parentNavigationElement)}
            className={classes.subNavigationTitleWrapper}>
            <Typography className={classes.subNavigationTitle}>{parentPage.title}</Typography>
            <KeyboardArrowRight className={classes.icon} />
          </Link>
        </ListItem>

        <ListItem key="back" className={classes.listItem}>
          <div className={classes.listItemTextLeft}>
            <ButtonBase onClick={handleBackClick} className={classes.BackIconWrapper}>
              <KeyboardArrowLeft className={classes.icon} />
              <Typography className={classes.label}>{t('navigation.back.label')}</Typography>
            </ButtonBase>
          </div>
        </ListItem>
        <div className={classes.listItemBottom} />
      </Visible>
      {map(filteredNavigationElements, navigationElement => {
        const navigationElementId = get(navigationElement, 'id');
        const subNavigationElements = get(navigationElement, 'subNavigationElements');
        const fullPath = get(navigationElement, 'pageReference.fullPath');

        let title = get(navigationElement, 'pageReference.menuTitle');

        if (isEmpty(title)) {
          title = get(navigationElement, 'pageReference.title');
        }
        if (isEmpty(title)) {
          title = get(navigationElement, 'title');
        }
        return (
          <>
            <ListItem key={navigationElementId} className={classes.listItem}>
              <div className={classes.listItemTextRight}>
                <Link
                  to={fullPath}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                  onClick={handleLinkClick(navigationElement)}>
                  {title}
                </Link>
              </div>
              <Visible hidden={isEmpty(subNavigationElements)}>
                <ListItemSecondaryAction className={classes.secondaryActionRight}>
                  <IconButton onClick={handleIconClick(navigationElement)} className={classes.iconWrapper}>
                    <KeyboardArrowRight className={classes.icon} />
                  </IconButton>
                </ListItemSecondaryAction>
              </Visible>
            </ListItem>
            <div className={classes.listItemBottom} />
          </>
        );
      })}
    </List>
  );
}

NavigationElements.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
  onNavigationElementClick: PropTypes.func,
};

NavigationElements.defaultProps = {
  classes: {},
  className: null,
  data: null,
  onNavigationElementClick: () => {},
};

export default withStyles(styles)(NavigationElements);
