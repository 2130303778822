/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */

import React from 'react';

import { CssBaseline } from '@material-ui/core';

import PrimaryThemeProvider from '../../src/themes/PrimaryThemeProvider';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <PrimaryThemeProvider>
        <CssBaseline />
        {element}
      </PrimaryThemeProvider>
    </>
  );
};
