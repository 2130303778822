import '../../fonts/Gotham/fontface.css';

const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(209, 231, 236)',
  darkBlue: 'rgb(113, 134, 140)',
  green: 'rgb(116, 165, 74)',
  lightGreen: 'rgb(232, 236, 226)',
  darkGreen: 'rgb(136,144,129)',
  darkPurple: 'rgb(66,44,64)',
  lightPurple: 'rgb(180, 156, 185)',
  red: 'rgb(200, 49, 69)',
  lighterRed: ' rgb(222, 194, 194)',
  darkRed: ' rgb(95, 39, 39)',
  darkBrown: 'rgb(95,66,64)',
  darkerBrown: 'rgb(71, 64, 64)',
  lightBrown: 'rgb(229,222,217)',
  lightYellow: 'rgb(255, 246, 233)',

  brightOrange: '#e68532',
  brightBlue: '#3b91a4',
  brightYellow: '#ffd700',
  brightGreen: '#7b9f34',
  brightBrown: '#86482c',
};

const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};

const brandColors = {
  tamarillo: '#891114',
  pirateGold: '#C78600',
};

const textColors = {
  bodyText: '#000000',
  mutedText: '#7D7D7D',
  disabledText: '#B9B9B9',
  lightText: colors.white,
};
const textFontFamilies = {
  fontTypography: 'Gotham, sans-serif',
  fontBody1: 'Gotham, sans-serif',
  fontBody2: 'Gotham, sans-serif',
  fontHeadings: 'Gotham, sans-serif',
  fontBotton: 'Gotham, sans-serif',
};

const textFontSizesMobile = {
  fontSizeLeadText: '1.125rem',
  fontSizeTypography: '0.938rem',
  fontSizeButton: '0.875rem',
  fontSizeBody1: '0.938rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '1.875rem',
  fontSizeH2: '1.5rem',
  fontSizeH3: '1.188rem',
  fontSizeH4: '1.125rem',
};
const textFontSizesDesktop = {
  fontSizeLeadText: '1.188rem',
  fontSizeTypography: '1rem',
  fontSizeButton: '1rem',
  fontSizeBody1: '1rem',
  fontSizeBody2: '0.813rem',
  fontSizeH1: '2.75rem',
  fontSizeH2: '2rem',
  fontSizeH3: '1.5rem',
  fontSizeH4: '1.125rem',
};
const textFontWeight = {
  typography: 'normal',
  button: '600',
  body1: 'normal',
  body2: 'normal',
  h1: '500',
  h2: '500',
  h3: '500',
  h4: '500',
};
const textLineHeightsMobile = {
  lineHeightLeadText: '1.5',
  lineHeightQuote: '1.47',
  lineHeightBody1: '1.47',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const textLineHeightsDesktop = {
  lineHeightLeadText: '1.58',
  lineHeightQuote: '1.5',
  lineHeightBody1: '1.5',
  lineHeightBody2: '1.54',
  lineHeightHeadings: 'normal',
};

const typography = {
  h1: {
    marginBottom: '2.5rem',
  },
  h2: {
    marginBottom: '1.75rem',
    textTransform: 'none',
  },
  h3: {
    marginBottom: '1rem',
  },
  h4: {
    marginBottom: '1rem',
  },
  h5: {
    marginBottom: '1rem',
  },
  h6: {
    marginBottom: '1rem',
  },
};

const pageTemplates = {
  landingPage: {
    mobile: {
      paddingTop: 5, // => value x 8px
    },
    desktop: {
      paddingTop: 7, // => value x 8px
    },
  },
  otherPageTemplates: {
    mobile: {
      paddingTop: 14, // => value x 8px
    },
    desktop: {
      paddingTop: 15, // => value x 8px
    },
  },
};

const linkColors = {
  link: '#A16B00',
  linkHover: '#895B00',
  linkPressed: '#684500',
};

const iconColors = {
  iconColor: '#000000',
};
const hr = {};

const backgroundColors = {
  paper: '#F8F6F3',
  grayLight: '#F0F0F0F0',
  grayDark: '#373737',
  goldLight: '#F1E8E2',
  overlay: 'rgba(51,51,51,0.5)',
};

const formColors = {
  inputBorder: '#B4B4B4',
  inputBackground: '#F0F0F0',
  inputActiveBackground: '#F0F0F0',
  inputActiveBorder: '#C78600',
  inputBorderRadius: '0',
};

const primaryButtonColors = {
  primaryButtonBackground: '#A16B00',
  primaryButtonBackgroundGradient: 'none',
  primaryButtonHoveredBackground: '#895B00',
  primaryButtonPressedBackground: '#684500',
  primaryButtonDisabledBackground: 'rgba(70, 58, 35, 0.4)',
  primaryButtonContents: '#FFFFFF',
};
const secondaryButtonColors = {
  secondaryButtonBackground: '#A16B00',
  secondaryButtonHoveredBackground: '#895B00',
  secondaryButtonPressedBackground: '#684500',
  secondaryButtonDisabledBackground: 'rgba(70, 58, 35, 0.4)',
  secondaryButtonContents: '#FFFFFF',
};

const outlinedButtonColors = {
  outlinedButton: '#A16B00',
  outlinedButtonHovered: '#fff',
  outlinedButtonPressed: '#684500',
  outlinedButtonDisabled: 'rgba(46, 24, 25, 0.4)',
  outlinedButtonHoveredBackground: '#A16B00',
};

const buttonSettings = {
  buttonPaddingDesktop: '8px 14px',
  buttonPaddingMobile: '12px 20px',
  borderRadius: '4px',
  textTransform: 'inherit',
};

const notificationColors = {
  infoBackground: '#E7EEF8',
  successBackground: '#E9F8EA',
  warningBackground: '#FDF2E9',
  errorBackground: '#F6E6E6',

  infoContents: '#3D68A8',
  successContents: '#218E2C',
  warningContents: '#E8770E',
  errorContents: '#D71717',
};

const header = {
  text: colors.pirateGold,
  background: colors.white,
  minHeight: '72px',
  paddingYDesktop: '60px',
  navigation: {
    activeLink: linkColors.linkPressed,
    activeLinkBorder: 'none',
    hoverText: linkColors.linkHover,
    subMenuBackground: colors.white,
    subMenuBulletPoints: colors.white,
  },
  border: {
    bottomWidth: '3px',
    style: 'solid',
  },
  icons: {
    color: colors.white,
    backgroundColor: colors.black,
  },
};

const footer = {
  border: {
    color: 'transparent',
    width: '3px',
  },
  divider: brandColors.tamarillo,
  text: colors.white,
  background: brandColors.tamarillo,
  logoBarBackground: brandColors.tamarillo,
  padding: {
    bottom: '50px',
  },
};

const accordion = {
  backgroundColor: backgroundColors.grayLight,
  borderRadius: '12px',
  color: linkColors.link,
  flexDirection: 'row',
  titleFontSize: '1.125rem',
  hover: {
    backgroundColor: colors.white,
    color: linkColors.hover,
  },
  expandIcon: {
    backgroundColor: 'inherit',
    hover: {
      backgroundColor: 'inherit',
    },
  },
  padding: {
    left: '12px',
    right: '8px',
  },
};

const languageSelector = {
  color: colors.black,
  border: {
    color: formColors.inputBorder,
    width: '2px',
  },
  margin: '0',
};

const table = {
  border: `solid 1px ${brandColors.pirateGold}`,
  borderRadius: '4px',
  head: {
    backgroundColor: brandColors.pirateGold,
    color: colors.white,
  },
  row: {
    even: {
      backgroundColor: colors.white,
    },
  },
  cell: {
    border: `solid 1px ${brandColors.pirateGold}`,
  },
};

const category = {
  outlined: {
    background: backgroundColors.grayLight,
    text: linkColors.link,
  },
  text: linkColors.link,
  hoverText: linkColors.linkHover,
};

const mobileNavigation = {
  text: colors.darkerBrown,
  icon: textColors.bodyText,
  xIconBackground: colors.lightBrown,
  logoBarBottomBorder: colors.darkBlue,
  navigationElementBorder: colors.lightGray,
  subNavigationTitleBackground: brandColors.tamarillo,
  subNavigationTitleColor: colors.white,
  drawer: {
    paper: {
      backgroundColors: backgroundColors.paper,
      widthDesktop: '350px',
    },
  },
};

export const blossaPrimaryTheme = {
  colors,
  breakPoints,
  brandColors,
  textColors,
  textFontFamilies,
  textFontSizesMobile,
  textFontSizesDesktop,
  textFontWeight,
  textLineHeightsMobile,
  textLineHeightsDesktop,
  typography,
  pageTemplates,
  linkColors,
  iconColors,
  backgroundColors,
  formColors,
  primaryButtonColors,
  secondaryButtonColors,
  outlinedButtonColors,
  buttonSettings,
  notificationColors,
  header,
  footer,
  accordion,
  languageSelector,
  table,
  category,
  mobileNavigation,
  hr,
};
