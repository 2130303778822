import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { find, get, isEmpty } from 'lodash';
import { Drawer, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import { useDrawer } from '../../../context/DrawerContext';

import NavigationElements from '../../NavigationElements';

const styles = theme => ({
  root: {},
  content: {
    overflow: 'auto',
  },
  toolbar: {
    padding: '15px',
    justifyContent: 'space-between',
  },
  iconButton: {
    padding: '10px',
  },
  icon: {
    fontSize: '2rem',
    color: theme.palette.secondary.main,
  },
});

function getActiveNavigationElement(navigationElements, pathname) {
  return find(navigationElements, {
    pageReference: {
      fullPath: pathname,
    },
  });
}

function AdditionalNavigation({ classes, className }) {
  const { pathname } = useLocation();
  const { closeDrawer, isDrawerOpen, drawerData } = useDrawer();

  const drawerOpen = isDrawerOpen('AdditionalNavigationDrawer');

  const navigationElements = useMemo(() => {
    return get(drawerData, 'additionalNavigation.flattenedNavigationElements');
  }, [drawerData]);

  const activeNavigationElementTitle = useMemo(() => {
    const activeNavigationElement = getActiveNavigationElement(navigationElements, pathname);

    let title = get(activeNavigationElement, 'pageReference.menuTitle');
    if (isEmpty(title)) {
      title = get(activeNavigationElement, 'pageReference.title');
    }

    return title;
  }, [navigationElements, pathname]);

  const handleNavigationElementClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Drawer anchor="left" className={clsx(classes.root, className)} open={drawerOpen} onClose={closeDrawer}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h3">{activeNavigationElementTitle}</Typography>
        <IconButton edge="end" aria-label="close" className={classes.iconButton} onClick={closeDrawer}>
          <Close className={classes.icon} />
        </IconButton>
      </Toolbar>
      <div className={classes.content}>
        <NavigationElements data={navigationElements} onNavigationElementClick={handleNavigationElementClick} />
      </div>
    </Drawer>
  );
}

AdditionalNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.object,
  children: PropTypes.func,
};

AdditionalNavigation.defaultProps = {
  classes: {},
  className: {},
  children: () => {},
};

export default withStyles(styles)(AdditionalNavigation);
