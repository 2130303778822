const settings = {
  brandSite: true,
  channels: [
    {
      channel: 'Valhalla EN',
      locale: 'en-US',
      languageCode: 'en',
      slugs: {
        ContentfulArticlePage: 'artiklar',
      },
    },
    {
      channel: 'Valhalla DE',
      locale: 'de-DE',
      languageCode: 'de',
      slugs: {
        ContentfulArticlePage: 'articles',
      },
    },
  ],
  localization: {
    default: 'en-US',
    defaultLanguageCode: 'en',
  },
};

export default settings;
