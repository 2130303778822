import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaDefaultFonts from './viinimaa';
import PhilipsonSoderbergDefaultFonts from './philipsonSoderberg';
import WennercoDefaultFonts from './wennerco';
import SkagerrakDefaultFonts from './skagerrak';
import WineworldFinlandDefaultFonts from './wineworldFinland';
import SocialwinesDefaultFonts from './socialwines';

function DefaultFonts(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaDefaultFonts {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaDefaultFonts {...props} />;
  }

  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return <PhilipsonSoderbergDefaultFonts {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <WennercoDefaultFonts {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakDefaultFonts {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <WineworldFinlandDefaultFonts {...props} />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <SocialwinesDefaultFonts {...props} />;
  }

  return null;
}

export default DefaultFonts;
