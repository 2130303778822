import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link rel="preload" as="style" href="https://use.typekit.net/znw0bje.css" />
      <link rel="stylesheet" href="https://use.typekit.net/znw0bje.css" />
      <link
        as="font"
        crossOrigin="anonymous"
        href="../../../../fonts/PlayfairDisplay/playfair-display-v30-latin-700.woff2"
        rel="preload"
        type="font/woff2"
      />
      <link
        as="font"
        crossOrigin="anonymous"
        href="../../../../fonts/PlayfairDisplay/playfair-display-v30-latin-regular.woff2"
        rel="preload"
        type="font/woff2"
      />
    </Helmet>
  );
}

export default DefaultFonts;
