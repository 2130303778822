import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import get from 'lodash/get';

const ContentSearchContext = createContext({
  getSearchTerm: () => {},
  getSelectedCheckboxes: () => {},
  getResults: () => {},
  updateSearchTerm: () => {},
  updateSelectedCheckboxes: () => {},
  updateResults: () => {},
  clearSearch: () => {},
});

const initialState = {};

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'updateSearchTerm': {
      const searchTerm = get(action, 'payload.searchTerm');
      const pageTitle = get(action, 'payload.pageTitle');
      return {
        ...state,
        [pageTitle]: {
          ...state[pageTitle],
          searchTerm,
        },
      };
    }

    case 'updateSelectedCheckboxes': {
      const selectedCheckboxes = get(action, 'payload.selectedCheckboxes');
      const pageTitle = get(action, 'payload.pageTitle');
      return {
        ...state,
        [pageTitle]: {
          ...state[pageTitle],
          selectedCheckboxes,
        },
      };
    }
    case 'updateResults': {
      const results = get(action, 'payload.results');
      const pageTitle = get(action, 'payload.pageTitle');
      return {
        ...state,
        [pageTitle]: {
          ...state[pageTitle],
          results,
        },
      };
    }
    case 'clearSearch': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function ContentSearchContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const updateSearchTerm = (pageTitle, searchTerm) => {
      dispatch({ type: 'updateSearchTerm', payload: { pageTitle, searchTerm } });
    };

    const updateSelectedCheckboxes = (pageTitle, selectedCheckboxes) => {
      dispatch({ type: 'updateSelectedCheckboxes', payload: { pageTitle, selectedCheckboxes } });
    };

    const updateResults = (pageTitle, results) => {
      dispatch({ type: 'updateResults', payload: { pageTitle, results } });
    };

    const clearSearch = () => {
      dispatch({ type: 'cleanSearch', payload: {} });
    };

    return {
      getSearchTerm: pageTitle => {
        return get(state, `${pageTitle}.searchTerm`, '');
      },
      getSelectedCheckboxes: pageTitle => {
        return get(state, `${pageTitle}.selectedCheckboxes`, []);
      },
      getResults: pageTitle => {
        return get(state, `${pageTitle}.resutls`, []);
      },
      updateSearchTerm,
      updateSelectedCheckboxes,
      updateResults,
      clearSearch,
    };
  }, [state]);

  return <ContentSearchContext.Provider value={contextValue}>{children}</ContentSearchContext.Provider>;
}

ContentSearchContextProvider.propTypes = {
  children: PropTypes.node,
};

ContentSearchContextProvider.defaultProps = {
  children: null,
};

export function useContentSearchContext() {
  const context = useContext(ContentSearchContext);
  if (isNil(context)) {
    throw new Error('Content Search Contex must be used within a ContentSearchContextProvider!');
  }
  return context;
}
