import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Auth0Provider } from '@auth0/auth0-react';

import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';

import { DialogProvider } from '../../../context/DialogContext';
import { PopUpProvider } from '../../../context/PopUpContext';
import { MessageProvider } from '../../../context/MessageContext';
import { DrawerProvider } from '../../../context/DrawerContext';
import { ReactQueryProvider } from '../../../context/ReactQueryContext';
import { ContentProvider } from '../../../context/ContentContext';
import { ActiveNavigationProvider } from '../../../context/ActiveNavigationContext';
import { ContentSearchContextProvider } from '../../../context/ContentSearchContext';
import { KlevuSearchProvider } from '../../../context/KlevuSearchContext';

import { TranslationProvider } from '../../../context/TranslationContext';

import GoogleTagManager from '../../GoogleTagManager';
import FrosmoScript from '../../FrosmoScript';
import DefaultFonts from '../../DefaultFonts.js';
import Favicon from '../../Favicon';
import MetaDomainVerificationTag from '../../MetaDomainVerificationTag';
import KlevuInit from '../../../search/KlevuInit';

const onRedirectCallback = appState => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};

function RootWrapper({ element }) {
  return (
    <>
      <Favicon />
      <GoogleTagManager />
      <FrosmoScript />
      <MetaDomainVerificationTag />
      <KlevuInit />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://use.typekit.net" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://p.typekit.net" crossOrigin="anonymous" />
      </Helmet>
      <DefaultFonts />
      <ReactQueryProvider>
        <TranslationProvider>
          <KlevuSearchProvider>
            <DialogProvider>
              <CookiesProvider>
                <PopUpProvider>
                  <MessageProvider>
                    <ActiveNavigationProvider>
                      <DrawerProvider>
                        <ContentSearchContextProvider>
                          <ContentProvider>
                            <Auth0Provider
                              domain={process.env.GATSBY_AUTH0_DOMAIN}
                              clientId={process.env.GATSBY_AUTH0_CLIENTID}
                              authorizationParams={{ redirect_uri: process.env.GATSBY_AUTH0_REDIRECT_URI }}
                              onRedirectCallback={onRedirectCallback}>
                              {element}
                            </Auth0Provider>
                          </ContentProvider>
                        </ContentSearchContextProvider>
                      </DrawerProvider>
                    </ActiveNavigationProvider>
                  </MessageProvider>
                </PopUpProvider>
              </CookiesProvider>
            </DialogProvider>
          </KlevuSearchProvider>
        </TranslationProvider>
      </ReactQueryProvider>
    </>
  );
}

RootWrapper.propTypes = {
  element: PropTypes.node,
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RootWrapper.defaultProps = {
  element: null,
  props: null,
};

export default RootWrapper;
