import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

function SkagerrakFavicon() {
  const themeFaciconQuery = useStaticQuery(graphql`
    query SkagerrakThemeFaviconQuery {
      allContentfulTheme(
        filter: {
          channel: { elemMatch: { channel: { in: ["Skagerrak EN", "Skagerrak DE"] } } }
          themeType: { eq: "Main theme" }
        }
      ) {
        nodes {
          title
          themeType
          themeStyles {
            favicon {
              cloudinaryImage {
                public_id
                url
                secure_url
              }
            }
          }
        }
      }
    }
  `);

  const cloudinaryImage = get(themeFaciconQuery, 'allContentfulTheme.nodes.0.themeStyles.favicon.cloudinaryImage.0');
  const publicId = get(cloudinaryImage, 'public_id');
  const url = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/image/upload/c_limit,q_auto:good,w_32/${publicId}`;

  return (
    <Helmet>
      <link rel="icon" type="image/x-icon" href={url} />
    </Helmet>
  );
}

export default SkagerrakFavicon;
