import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { get, isEqual, isNil } from 'lodash';

const PopUpContext = createContext({
  hasBeenClosed: false,
  openPopUp: () => {},
  closePopUp: () => {},
  isPopUpOpen: () => {},
});

const initialState = { name: null, hasBeenClosed: false };

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'openPopUp': {
      const name = get(action, 'payload.name');

      return { ...state, name };
    }
    case 'closePopUp': {
      return { name: null, hasBeenClosed: true };
    }

    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function PopUpProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const openPopUp = name => {
      dispatch({ type: 'openPopUp', payload: { name } });
    };

    const closePopUp = () => {
      dispatch({ type: 'closePopUp', payload: {} });
    };

    const isPopUpOpen = name => {
      return isEqual(name, get(state, 'name'));
    };

    return {
      hasBeenClosed: get(state, 'hasBeenClosed'),
      openPopUp,
      closePopUp,
      isPopUpOpen,
    };
  }, [state]);

  return <PopUpContext.Provider value={contextValue}>{children}</PopUpContext.Provider>;
}

PopUpProvider.propTypes = {
  children: PropTypes.node,
};

PopUpProvider.defaultProps = {
  children: null,
};

export function usePopUp() {
  const context = useContext(PopUpContext);
  if (isNil(context)) {
    throw new Error('usePopUp must be used within a DialogProvider');
  }
  return context;
}
