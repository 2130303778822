import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { get, isNil } from 'lodash';

const ActiveNavigationContext = createContext({
  activeNav: {},
  updateActiveNav: () => {},
  clearActiveNav: () => {},
});

const initialState = {};

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'updateActiveNav': {
      const navData = get(action, 'payload.navData');
      return { ...state, activeNav: navData };
    }
    case 'clearActiveNav': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function ActiveNavigationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const updateActiveNav = navData => {
      dispatch({ type: 'updateActiveNav', payload: { navData } });
    };
    const clearActiveNav = () => {
      dispatch({ type: 'clearActiveNav', payload: {} });
    };

    return {
      activeNav: get(state, 'activeNav'),
      updateActiveNav,
      clearActiveNav,
    };
  }, [state]);

  return <ActiveNavigationContext.Provider value={contextValue}>{children}</ActiveNavigationContext.Provider>;
}

ActiveNavigationProvider.propTypes = {
  children: PropTypes.node,
};

ActiveNavigationProvider.defaultProps = {
  children: null,
};

export function useActiveNavigation() {
  const context = useContext(ActiveNavigationContext);
  if (isNil(context)) {
    throw new Error('useActiveNavigation must be used within a DrawerProvider');
  }
  return context;
}
