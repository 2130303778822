/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */

import React from 'react';

import RootWrapper from '../../src/components/layout/RootWrapper';
import PageWrapper from '../../src/components/layout/PageWrapper';

export const wrapRootElement = props => {
  return <RootWrapper {...props} />;
};

export const wrapPageElement = props => {
  return <PageWrapper {...props} />;
};
