import isEqual from 'lodash/isEqual';

import { siteIds } from '../constants';

import viinimaaSettings from './viinimaa/settings';
import folkofolkSettings from './folkofolk/settings';
import anoraProSettings from './anoraPro/settings';
import bibendumSettings from './bibendum/settings';
import blossaSettings from './blossa/settings';
import philipsonSoderbergSettings from './philipson-soderberg/settings';
import valhallaSettings from './valhalla/settings';
import gammeldanskSettings from './gammeldansk/settings';
import wennercoSettings from './wennerco/settings';
import koskenkorvaSettings from './koskenkorva/settings';
import opAndersonSettings from './op-anderson/settings';
import skagerrakSettings from './skagerrak/settings';
import wineworldFinlandSettings from './wineworld-finland/settings';
import linieAquavitSettings from './linieAquavit/settings';
import socialwinesSettings from './socialwines/settings';

export const siteId = process.env.GATSBY_SITE_ID;

export const settings = (() => {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return viinimaaSettings;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return folkofolkSettings;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return anoraProSettings;
  }
  if (isEqual(siteId, siteIds.Bibendum)) {
    return bibendumSettings;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return blossaSettings;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return philipsonSoderbergSettings;
  }
  if (isEqual(siteId, siteIds.Valhalla)) {
    return valhallaSettings;
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return gammeldanskSettings;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return wennercoSettings;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return koskenkorvaSettings;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return opAndersonSettings;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return skagerrakSettings;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return wineworldFinlandSettings;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return linieAquavitSettings;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return socialwinesSettings;
  }

  return {};
})();

export const { brandSite } = settings;
