import React from 'react';

import { Helmet } from 'react-helmet';
import { isSSR } from '../../../../utils/windowUtils';

function FrosmoScript() {
  if (isSSR()) {
    return null;
  }
  return (
    <Helmet>
      <script type="text/javascript" charset="utf-8" async src="//d2oarllo6tn86.cloudfront.net/frosmo.easy.js" />
      <script type="text/javascript" charset="utf-8" async src="//d2oarllo6tn86.cloudfront.net/sites/viinimaa_fi.js" />
    </Helmet>
  );
}

FrosmoScript.propTypes = {};

FrosmoScript.defaultProps = {};

export default FrosmoScript;
