import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Jost&display=swap" rel="stylesheet" />
    </Helmet>
  );
}

export default DefaultFonts;
