const settings = {
  channels: [
    {
      channel: 'Viinimaa FI',
      store: 'viinimaa_fi',
      locale: 'fi-FI',
      languageCode: 'fi',
      slugs: {
        ContentfulArticlePage: 'artikkelit',
        MagentoProduct: 'juomat',
      },
    },
  ],
  localization: {
    default: 'fi-FI',
    defaultLanguageCode: 'fi',
  },
};

export default settings;
