const settings = {
  brandSite: true,
  channels: [
    {
      channel: 'Philipson Söderberg EN',
      locale: 'en-US',
      languageCode: 'en',
      slugs: {
        ContentfulArticlePage: 'articles',
      },
    },
    {
      channel: 'Philipson Söderberg SE',
      locale: 'sv-SE',
      languageCode: 'sv',
      slugs: {
        ContentfulArticlePage: 'artiklar',
      },
    },
  ],
  localization: {
    default: 'en-US',
    defaultLanguageCode: 'en',
  },
};

export default settings;
