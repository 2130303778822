import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, map, filter, find, isEqual, isNull } from 'lodash';
import { ButtonBase, IconButton, List, ListItem, Typography, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import { useActiveNavigation } from '../../../context/ActiveNavigationContext';
import Visible from '../../../shared/Visible';
import Link from '../../Link';
import { fofNavigationTitle } from '../../../../constants';

import MartiniIcon from '../../../../images/folkofolk/Martini.svg';
import MartiniIconWhite from '../../../../images/folkofolk/Martini-white.svg';

import WineIcon from '../../../../images/folkofolk/Wine.svg';
import WineIconWhite from '../../../../images/folkofolk/Wine-white.svg';

const styles = theme => ({
  root: {
    padding: '0',
  },
  subNavigationTitleBar: {
    display: 'block',
    height: '56px',
    backgroundColor: theme.palette.mobileNavigation.subNavigationTitleBackground,
    padding: '12px 12px 12px 20px',
  },
  subNavigationTitleLink: {
    display: 'flex',
    minHeight: '56px',
    padding: '12px 12px 12px 20px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    fontSize: '22px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  subNavigationTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: { padding: '13px', color: 'inherit' },
  icon: { fontSize: '20px', color: theme.palette.mobileNavigation.icon },
  arrowIcon: { fontSize: '20px', color: 'inherit' },
  label: {
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
  },
  listItem: {
    padding: '0 ',
    borderBottom: `1px solid ${theme.palette.mobileNavigation.navigationElementBorder}`,
  },

  buttonBase: { height: '56px' },

  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '46px',
    flexGrow: '1',
    padding: '10px 10px 10px 20px',
    color: theme.palette.mobileNavigation.text,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
  },
  activeLink: {
    color: theme.palette.header.navigation.activeLink,
  },
  secondaryActionRight: {
    right: '0',
  },

  spirits: {
    backgroundColor: theme.palette.common.lightBrown,
  },

  activeSpirits: {
    backgroundColor: theme.palette.common.darkBrown,
    color: theme.palette.common.white,
  },
  wines: {
    backgroundColor: theme.palette.common.lighterRed,
  },

  activeWines: {
    backgroundColor: theme.palette.common.darkPurple,
    color: theme.palette.common.white,
  },
  image: { marginRight: '8px' },
});

function getActiveNavigationElement(navigationElements, pathname) {
  return find(navigationElements, {
    pageReference: {
      fullPath: pathname,
    },
  });
}

function getFilteredNavigationElements(navigationElements, parentNavigationElement) {
  return filter(navigationElements, navigationElement => {
    return isEqual(get(navigationElement, 'parentNavigationElement.id'), get(parentNavigationElement, 'id'));
  });
}
function getParentNavigationElements(navigationElements, navigationElement) {
  const parent = filter(navigationElements, element => {
    return isEqual(get(element, 'id'), get(navigationElement, 'parentNavigationElement.id'));
  });
  return get(parent, '0', null);
}
function setActiveMainNavigation(navigationElement, wines, updateFunction) {
  const element = {
    id: get(navigationElement, 'id'),
    fullPath: get(navigationElement, 'pageReference.fullPath'),
    children: get(navigationElement, 'subNavigationElements'),
    title: get(navigationElement, 'title'),
    index: isEqual(get(navigationElement, 'title'), wines) ? 1 : 0,
  };
  updateFunction(element);
}

function getNavigationElement(navigationElements, navigationElementId) {
  const navigationElement = find(navigationElements, {
    id: navigationElementId,
  });

  return isEmpty(navigationElement) ? null : navigationElement;
}

function NavigationElements({ classes, className, data: navigationElements, onNavigationElementClick }) {
  const wines = get(fofNavigationTitle, 'wines');
  const spirits = get(fofNavigationTitle, 'spirits');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { activeNav, updateActiveNav } = useActiveNavigation();

  const [parentNavigationElement, setParentNavigationElement] = useState(null);

  const activeNavigationElement = useMemo(() => {
    return getActiveNavigationElement(navigationElements, pathname);
  }, [navigationElements, pathname]);

  const filteredNavigationElements = useMemo(() => {
    return getFilteredNavigationElements(navigationElements, parentNavigationElement);
  }, [navigationElements, parentNavigationElement]);

  const handleBackClick = useCallback(() => {
    const navigationElementId = get(parentNavigationElement, 'parentNavigationElement.id');
    const navigationElement = getNavigationElement(navigationElements, navigationElementId);
    setParentNavigationElement(navigationElement);
  }, [navigationElements, parentNavigationElement]);

  const handleIconClick = useCallback(
    navigationElement => () => {
      if (isEqual(get(navigationElement, 'title'), wines) || isEqual(get(navigationElement, 'title'), spirits)) {
        setActiveMainNavigation(navigationElement, wines, updateActiveNav);
      }
      setParentNavigationElement(navigationElement);
    },
    [],
  );

  const handleLinkClick = useCallback(
    navigationElement => () => {
      let parenntElement = getParentNavigationElements(navigationElements, navigationElement);
      while (get(parenntElement, 'parentNavigationElement.id', false)) {
        parenntElement = getParentNavigationElements(navigationElements, parenntElement);
      }
      if (isEmpty(parenntElement)) {
        setActiveMainNavigation(navigationElement, wines, updateActiveNav);
      } else {
        setActiveMainNavigation(parenntElement, wines, updateActiveNav);
      }
      onNavigationElementClick(navigationElement);
    },
    [onNavigationElementClick],
  );

  useEffect(() => {
    const navigationElementId = get(activeNavigationElement, 'parentNavigationElement.id');
    const navigationElement = getNavigationElement(navigationElements, navigationElementId);
    setParentNavigationElement(navigationElement);

    if (isNull(parentNavigationElement)) {
      if (isEqual(get(activeNav, 'title'), wines) || isEqual(get(activeNav, 'title'), spirits)) {
        const mainNavigationElement = getNavigationElement(navigationElements, get(activeNav, 'id'));
        setParentNavigationElement(mainNavigationElement);
      }
    }

    let parenntElement = getParentNavigationElements(navigationElements, navigationElement);
    while (get(parenntElement, 'parentNavigationElement.id', false)) {
      parenntElement = getParentNavigationElements(navigationElements, parenntElement);
    }
    if (isEmpty(parenntElement)) {
      setActiveMainNavigation(navigationElement, wines, updateActiveNav);
    } else {
      setActiveMainNavigation(parenntElement, wines, updateActiveNav);
    }
  }, [navigationElements, activeNavigationElement]);

  return (
    <List component="nav" className={clsx(classes.root, className)}>
      <Visible hidden={isEmpty(parentNavigationElement)}>
        <Link
          to={get(parentNavigationElement, 'pageReference.fullPath')}
          onClick={handleLinkClick(parentNavigationElement)}
          className={clsx(
            classes.subNavigationTitleLink,
            isEqual(get(activeNav, 'title'), wines) ? classes.activeWines : null,
            isEqual(get(activeNav, 'title'), spirits) ? classes.activeSpirits : null,
          )}>
          <div className={classes.subNavigationTitle}>
            {isEqual(get(parentNavigationElement, 'title'), wines) ? (
              <img src={WineIconWhite} alt="icon" width="24" height="24" className={classes.image} loading="lazy" />
            ) : null}
            {isEqual(get(parentNavigationElement, 'title'), spirits) ? (
              <img src={MartiniIconWhite} alt="icon" width="24" height="24" className={classes.image} loading="lazy" />
            ) : null}
            {!isEmpty(get(parentNavigationElement, 'pageReference.menuTitle'))
              ? get(parentNavigationElement, 'pageReference.menuTitle')
              : get(parentNavigationElement, 'title')}
          </div>
          <KeyboardArrowRight />
        </Link>
        <div
          className={clsx(
            isEqual(get(activeNav, 'title'), wines) ? classes.wines : null,
            isEqual(get(activeNav, 'title'), spirits) ? classes.spirits : null,
          )}>
          <ButtonBase onClick={handleBackClick} className={classes.iconWrapper}>
            <KeyboardArrowLeft className={classes.icon} />
            <Typography className={classes.label}>{t('navigation.back.label')}</Typography>
          </ButtonBase>
        </div>
      </Visible>
      {map(filteredNavigationElements, navigationElement => {
        const navigationElementId = get(navigationElement, 'id');
        const subNavigationElements = get(navigationElement, 'subNavigationElements');
        const fullPath = get(navigationElement, 'pageReference.fullPath');
        let isMainNav = false;
        if (isEqual(get(navigationElement, 'title'), wines) || isEqual(get(navigationElement, 'title'), spirits)) {
          isMainNav = true;
        }
        let title = get(navigationElement, 'pageReference.menuTitle');
        if (isEmpty(title)) {
          title = get(navigationElement, 'pageReference.title');
        }
        if (isEmpty(title)) {
          title = get(navigationElement, 'title');
        }

        return (
          <>
            {isEqual(get(navigationElement, 'title'), spirits) && (
              <ListItem
                key={navigationElementId}
                className={clsx(
                  classes.listItem,
                  isEqual(get(activeNav, 'title'), spirits) ? classes.activeSpirits : classes.spirits,
                )}>
                <Link
                  to={fullPath}
                  className={clsx(
                    classes.link,
                    isEqual(get(activeNav, 'title'), spirits) ? classes.activeSpirits : classes.spirits,
                  )}
                  activeClassName={null}
                  partiallyActive
                  onClick={handleLinkClick(navigationElement)}>
                  <img
                    src={isEqual(get(activeNav, 'title'), spirits) ? MartiniIconWhite : MartiniIcon}
                    alt="icon"
                    width="20"
                    height="20"
                    className={classes.image}
                    loading="lazy"
                  />
                  {title}
                </Link>
                <Visible hidden={isEmpty(subNavigationElements)}>
                  <div className={clsx(classes.secondaryActionRight)}>
                    <IconButton onClick={handleIconClick(navigationElement)} className={classes.iconWrapper}>
                      <KeyboardArrowRight className={classes.arrowIcon} />
                    </IconButton>
                  </div>
                </Visible>
              </ListItem>
            )}
            {isEqual(get(navigationElement, 'title'), wines) && (
              <ListItem
                key={navigationElementId}
                className={clsx(
                  classes.listItem,
                  isEqual(get(activeNav, 'title'), wines) ? classes.activeWines : classes.wines,
                )}>
                <Link
                  to={fullPath}
                  className={clsx(
                    classes.link,
                    isEqual(get(activeNav, 'title'), wines) ? classes.activeWines : classes.wines,
                  )}
                  activeClassName={null}
                  partiallyActive
                  onClick={handleLinkClick(navigationElement)}>
                  <img
                    src={isEqual(get(activeNav, 'title'), wines) ? WineIconWhite : WineIcon}
                    alt="icon"
                    width="20"
                    height="20"
                    className={classes.image}
                    loading="lazy"
                  />
                  {title}
                </Link>
                <Visible hidden={isEmpty(subNavigationElements)}>
                  <div className={clsx(classes.secondaryActionRight)}>
                    <IconButton onClick={handleIconClick(navigationElement)} className={classes.iconWrapper}>
                      <KeyboardArrowRight className={classes.arrowIcon} />
                    </IconButton>
                  </div>
                </Visible>
              </ListItem>
            )}
            {!isMainNav && (
              <ListItem key={navigationElementId} className={clsx(classes.listItem)}>
                <Link
                  to={fullPath}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                  partiallyActive
                  onClick={handleLinkClick(navigationElement)}>
                  {title}
                </Link>
                <Visible hidden={isEmpty(subNavigationElements)}>
                  <div className={classes.secondaryActionRight}>
                    <IconButton onClick={handleIconClick(navigationElement)} className={classes.iconWrapper}>
                      <KeyboardArrowRight className={classes.icon} />
                    </IconButton>
                  </div>
                </Visible>
              </ListItem>
            )}
          </>
        );
      })}
    </List>
  );
}

NavigationElements.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
  onNavigationElementClick: PropTypes.func,
};

NavigationElements.defaultProps = {
  classes: {},
  className: null,
  data: null,
  onNavigationElementClick: () => {},
};

export default withStyles(styles)(NavigationElements);
