import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { get, isEqual } from 'lodash';
import { Snackbar, withStyles } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { messageTypes } from '../../../constants';

import { useMessage } from '../../context/MessageContext';

const styles = theme => ({
  content: {
    ...theme.typography.body2,
    alignItems: 'center',
    backgroundColor: ({ message }) => {
      let backgroundColor;

      switch (get(message, 'type')) {
        case messageTypes.Error:
          backgroundColor = theme.palette.error.light;
          break;
        case messageTypes.Success:
          backgroundColor = theme.palette.success.light;
          break;
        case messageTypes.Warning:
          backgroundColor = theme.palette.warning.light;
          break;
        case messageTypes.Info:
          backgroundColor = theme.palette.info.light;
          break;
        default:
          backgroundColor = theme.palette.grey['100'];
          break;
      }
      return backgroundColor;
    },
    borderRadius: 0,
    boxShadow: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    padding: '.8rem 1.5rem',
    '& .MuiAlert-icon > svg': {
      fill: ({ message }) => {
        let fillColor;

        switch (get(message, 'type')) {
          case messageTypes.Error:
            fillColor = theme.palette.error.main;
            break;
          case messageTypes.Success:
            fillColor = theme.palette.success.main;
            break;
          case messageTypes.Warning:
            fillColor = theme.palette.warning.main;
            break;
          case messageTypes.Info:
            fillColor = theme.palette.info.main;
            break;
          default:
            fillColor = theme.palette.grey['800'];
            break;
        }
        return fillColor;
      },
    },
    '& .MuiAlert-message': {
      paddingTop: '11px',
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Message({ classes, message }) {
  const { removeMessage } = useMessage();

  const id = get(message, 'id');
  const text = get(message, 'text');

  const closeMessage = useCallback(
    (event, reason) => {
      if (isEqual(reason, 'clickaway')) {
        return;
      }
      removeMessage(id);
    },
    [removeMessage, id],
  );
  // eslint-disable-next-line no-console

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={closeMessage}
      open>
      <Alert
        onClose={closeMessage}
        className={classes.content}
        severity={get(message, 'type').toLowerCase()}
        iconMapping={{
          success: <CheckIcon fontSize="inherit" />,
          error: <ReportProblemOutlinedIcon fontSize="inherit" />,
        }}>
        {text}
      </Alert>
    </Snackbar>
  );
}

Message.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.object,
};

Message.defaultProps = {
  message: null,
  classes: {},
  className: null,
};

export default withStyles(styles)(Message);
