import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

export const isSSR = () => {
  return isEqual(typeof window, 'undefined');
};

export const getWindowHref = () => {
  return isSSR() ? undefined : get(window, 'location.href');
};

export const getWindowPathName = () => {
  return isSSR() ? undefined : get(window, 'location.pathname');
};
export const getWindowLocationSearch = () => {
  return isSSR() ? undefined : get(window, 'location.search');
};
