import React from 'react';

import { isEqual } from 'lodash';
import { ThemeProvider } from '@material-ui/core';

import { siteIds } from '../constants';
import { siteId, brandSite } from '../sites';

import viinimaaTheme from './viinimaa/primaryTheme';
import folkofolkTheme from './folkofolk/primaryTheme';
import anoraProTheme from './anoraPro/primaryTheme';
import brandsTheme from './brands/primaryTheme';

function getPrimaryTheme() {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return viinimaaTheme;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return folkofolkTheme;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return anoraProTheme;
  }
  if (brandSite) {
    return brandsTheme;
  }

  return null;
}

function PrimaryThemeProvider(props) {
  return <ThemeProvider theme={getPrimaryTheme()} {...props} />;
}

export default PrimaryThemeProvider;
