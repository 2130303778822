import React from 'react';
import PropTypes from 'prop-types';

import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export function ReactQueryProvider(props) {
  return <QueryClientProvider {...props} client={client} />;
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node,
};

ReactQueryProvider.defaultProps = {
  children: null,
};
