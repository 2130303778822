import React from 'react';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { createTheme } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';
import { fofNavigationBreakpoint, siteIds } from '../../constants';
import { siteId } from '../../sites';

import { bibendumPrimaryTheme } from '../bibendum/primaryTheme';
import { philipsonSoderbergPrimaryTheme } from '../philipsonSoderberg/primaryTheme';
import { blossaPrimaryTheme } from '../blossa/primaryTheme';
import { valhallaPrimaryTheme } from '../valhalla/primaryTheme';
import { gammeldanskPrimaryTheme } from '../gammeldansk/primaryTheme';
import { wennercoPrimaryTheme } from '../wennerco/primaryTheme';
import { koskenkorvaPrimaryTheme } from '../koskenkorva/primaryTheme';
import { opAndersonPrimaryTheme } from '../opAnderson/primaryTheme';
import { skagerrakPrimaryTheme } from '../skagerrak/primaryTheme';
import { wineworldFinlandPrimaryTheme } from '../wineworld-finland/primaryTheme';
import { LinieAquavitPrimaryTheme } from '../LinieAquavit/primaryTheme';
import { SocialwinesPrimaryTheme } from '../socialwines/primaryTheme';

let brandPrimaryTheme = bibendumPrimaryTheme;
if (isEqual(siteId, siteIds.Bibendum)) {
  brandPrimaryTheme = bibendumPrimaryTheme;
} else if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
  brandPrimaryTheme = philipsonSoderbergPrimaryTheme;
} else if (isEqual(siteId, siteIds.Blossa)) {
  brandPrimaryTheme = blossaPrimaryTheme;
} else if (isEqual(siteId, siteIds.Valhalla)) {
  brandPrimaryTheme = valhallaPrimaryTheme;
} else if (isEqual(siteId, siteIds.Gammeldansk)) {
  brandPrimaryTheme = gammeldanskPrimaryTheme;
} else if (isEqual(siteId, siteIds.Wennerco)) {
  brandPrimaryTheme = wennercoPrimaryTheme;
} else if (isEqual(siteId, siteIds.Koskenkorva)) {
  brandPrimaryTheme = koskenkorvaPrimaryTheme;
} else if (isEqual(siteId, siteIds.OpAnderson)) {
  brandPrimaryTheme = opAndersonPrimaryTheme;
} else if (isEqual(siteId, siteIds.Skagerrak)) {
  brandPrimaryTheme = skagerrakPrimaryTheme;
} else if (isEqual(siteId, siteIds.WineworldFinland)) {
  brandPrimaryTheme = wineworldFinlandPrimaryTheme;
} else if (isEqual(siteId, siteIds.LinieAquavit)) {
  brandPrimaryTheme = LinieAquavitPrimaryTheme;
} else if (isEqual(siteId, siteIds.Socialwines)) {
  brandPrimaryTheme = SocialwinesPrimaryTheme;
}

const theme = createTheme({});

const primaryTheme = createTheme({
  breakpoints: {
    values: brandPrimaryTheme.breakPoints,
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  typography: {
    fontFamily: brandPrimaryTheme.textFontFamilies.fontTypography,
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h1: {
      color: brandPrimaryTheme.textColors.headingColor,
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h1', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeH1,
      fontWeight: brandPrimaryTheme.textFontWeight.h1,
      marginBottom: brandPrimaryTheme.typography.h1.marginBottom,
      lineHeight: get(
        brandPrimaryTheme,
        'textLineHeightsMobile.h1',
        brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      ),
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeH1,
        lineHeight: get(
          brandPrimaryTheme,
          'textLineHeightsDesktop.h1',
          brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
        ),
      },
    },
    h2: {
      color: brandPrimaryTheme.textColors.headingColor,
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h2', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeH2,
      fontWeight: brandPrimaryTheme.textFontWeight.h2,
      marginBottom: brandPrimaryTheme.typography.h2.marginBottom,
      lineHeight: get(
        brandPrimaryTheme,
        'textLineHeightsMobile.h2',
        brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      ),
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeH2,
        lineHeight: get(
          brandPrimaryTheme,
          'textLineHeightsDesktop.h2',
          brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
        ),
      },
    },
    h3: {
      color: brandPrimaryTheme.textColors.headingColor,
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h3', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeH3,
      fontWeight: brandPrimaryTheme.textFontWeight.h3,
      marginBottom: brandPrimaryTheme.typography.h3.marginBottom,
      lineHeight: get(
        brandPrimaryTheme,
        'textLineHeightsMobile.h3',
        brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      ),
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeH3,
        lineHeight: get(
          brandPrimaryTheme,
          'textLineHeightsDesktop.h3',
          brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
        ),
      },
    },
    h4: {
      color: brandPrimaryTheme.textColors.headingColor,
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h4', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeH4,
      fontWeight: brandPrimaryTheme.textFontWeight.h4,
      marginBottom: brandPrimaryTheme.typography.h4.marginBottom,
      lineHeight: get(
        brandPrimaryTheme,
        'textLineHeightsMobile.h4',
        brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      ),
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeH4,
        lineHeight: get(
          brandPrimaryTheme,
          'textLineHeightsDesktop.h4',
          brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
        ),
      },
    },
    h5: {
      color: brandPrimaryTheme.textColors.headingColor,
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h5', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: get(
        brandPrimaryTheme,
        'textFontSizesMobile.fontSizeH5',
        brandPrimaryTheme.textFontSizesMobile.fontSizeH4,
      ),
      fontWeight: brandPrimaryTheme.textFontWeight.h4,
      marginBottom: brandPrimaryTheme.typography.h5.marginBottom,
      lineHeight: get(
        brandPrimaryTheme,
        'textLineHeightsMobile.h5',
        brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      ),
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: get(
          brandPrimaryTheme,
          'textFontSizesDesktop.fontSizeH5',
          brandPrimaryTheme.textFontSizesDesktop.fontSizeH4,
        ),
        lineHeight: get(
          brandPrimaryTheme,
          'textLineHeightsDesktop.h5',
          brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
        ),
      },
    },
    h6: {
      fontFamily: get(brandPrimaryTheme, 'textFontFamilies.h6', brandPrimaryTheme.textFontFamilies.fontHeadings),
      fontSize: get(
        brandPrimaryTheme,
        'textFontSizesMobile.fontSizeH6',
        brandPrimaryTheme.textFontSizesMobile.fontSizeH4,
      ),
      fontWeight: brandPrimaryTheme.textFontWeight.body1,
      marginBottom: brandPrimaryTheme.typography.h6.marginBottom,
      lineHeight: brandPrimaryTheme.textLineHeightsMobile.lineHeightHeadings,
      textTransform: get(brandPrimaryTheme, 'typography.h2.textTransform', 'none'),
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: get(
          brandPrimaryTheme,
          'textFontSizesDesktop.fontSizeH6',
          brandPrimaryTheme.textFontSizesDesktop.fontSizeH4,
        ),
        lineHeight: brandPrimaryTheme.textLineHeightsDesktop.lineHeightHeadings,
      },
    },
    caption: {
      fontSize: 'inherit',
      fontStyle: 'italic',
      lineHeight: '1.5',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    body1: {
      fontFamily: brandPrimaryTheme.textFontFamilies.fontBody1,
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeBody1,
      lineHeight: brandPrimaryTheme.textLineHeightsMobile.lineHeightBody1,
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeBody1,
        lineHeight: brandPrimaryTheme.textLineHeightsDesktop.lineHeightBody1,
      },
    },
    body2: {
      fontFamily: brandPrimaryTheme.textFontFamilies.fontBody2,
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeBody2,
      lineHeight: brandPrimaryTheme.textLineHeightsMobile.lineHeightBody2,
      [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
        fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeBody2,
        lineHeight: brandPrimaryTheme.textLineHeightsDesktop.lineHeightBody2,
      },
    },
    navigationLink: {
      fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeBody1,
      fontFamily: brandPrimaryTheme.textFontFamilies.fontBody1,
      fontWeight: 'bold',
      color: get(brandPrimaryTheme, 'header.color', brandPrimaryTheme.textColors.bodyText),
    },
    descriptionRow: {
      fontSize: '.875rem',
    },
  },
  palette: {
    common: {
      black: brandPrimaryTheme.colors.black,
      white: brandPrimaryTheme.colors.white,
      gray: brandPrimaryTheme.colors.gray,
      lightGray: brandPrimaryTheme.colors.lightGray,
      lighterGray: brandPrimaryTheme.colors.lighterGray,
      mediumGray: brandPrimaryTheme.colors.mediumGray,
      darkGray: brandPrimaryTheme.colors.darkGray,
      blue: brandPrimaryTheme.colors.blue,
      lightBlue: brandPrimaryTheme.colors.lightBlue,
      red: brandPrimaryTheme.colors.red,
      darkRed: brandPrimaryTheme.colors.darkRed,
      green: brandPrimaryTheme.colors.green,
      lightGreen: brandPrimaryTheme.colors.lightGreen,
      darkBrown: brandPrimaryTheme.colors.darkBrown,
      darkerBrown: brandPrimaryTheme.colors.darkerBrown,
      darkPurple: brandPrimaryTheme.colors.darkPurple,
      lighterRed: brandPrimaryTheme.colors.lighterRed,
      lightBrown: brandPrimaryTheme.colors.lightBrown,
    },
    primary: {
      main: brandPrimaryTheme.textColors.bodyText,
      contrastText: brandPrimaryTheme.colors.white50,
    },
    secondary: {
      main: brandPrimaryTheme.textColors.bodyText,
      light: brandPrimaryTheme.colors.lightYellow,
    },
    text: {
      primary: brandPrimaryTheme.textColors.bodyText,
      secondary: brandPrimaryTheme.colors.white,
      muted: brandPrimaryTheme.textColors.mutedText,
      light: brandPrimaryTheme.textColors.lightText,
    },
    background: {
      default: brandPrimaryTheme.backgroundColors.paper,
      breadcrumbs: brandPrimaryTheme.backgroundColors.grayLight,
      linkBlock: brandPrimaryTheme.backgroundColors.grayLight,
      defaultColumn: 'transparent',
      defaultRow: 'transparent',
    },
    error: {
      main: brandPrimaryTheme.notificationColors.errorContents,
      light: brandPrimaryTheme.notificationColors.errorBackground,
    },
    success: {
      main: brandPrimaryTheme.notificationColors.successContents,
      light: brandPrimaryTheme.notificationColors.successBackground,
    },
    warning: {
      main: brandPrimaryTheme.notificationColors.warningContents,
      light: brandPrimaryTheme.notificationColors.warningBackground,
    },
    info: {
      main: brandPrimaryTheme.notificationColors.infoContents,
      light: brandPrimaryTheme.notificationColors.infoBackground,
    },
    headingFontFamily: { fontFamily: brandPrimaryTheme.textFontFamilies.fontHeadings },

    divider: brandPrimaryTheme.backgroundColors.grayLight,

    category: {
      outlined: {
        background: brandPrimaryTheme.category.outlined.background,
        text: brandPrimaryTheme.category.outlined.text,
      },
      text: brandPrimaryTheme.category.text,
      hoverText: brandPrimaryTheme.category.hoverText,
    },
    footer: {
      border: {
        color: brandPrimaryTheme.footer.border.color,
        width: brandPrimaryTheme.footer.border.width,
      },
      divider: brandPrimaryTheme.footer.divider,
      text: brandPrimaryTheme.footer.text,
      background: brandPrimaryTheme.footer.background,
      logoBarBackground: brandPrimaryTheme.footer.logoBarBackground,
      padding: brandPrimaryTheme.footer.padding,
      columns: {
        list: {
          '& > li::Marker': {
            color: brandPrimaryTheme.colors.white,
          },
        },
        texts: { color: brandPrimaryTheme.colors.white },
      },
    },
    header: {
      text: brandPrimaryTheme.header.text,
      background: brandPrimaryTheme.header.background,
      minHeight: brandPrimaryTheme.header.minHeight,
      paddingYDesktop: brandPrimaryTheme.header.paddingYDesktop,
      navigation: {
        activeLink: brandPrimaryTheme.header.navigation.activeLink,
        activeLinkBorder: brandPrimaryTheme.header.navigation.activeLinkBorder,
        hoverText: brandPrimaryTheme.header.navigation.hoverText,
        subMenuBackground: brandPrimaryTheme.header.navigation.subMenuBackground,
        subMenuBulletPoints: brandPrimaryTheme.header.navigation.subMenuBulletPoints,
      },
      border: {
        bottomWidth: get(brandPrimaryTheme, 'header.border.bottomWidth', '0'),
        style: get(brandPrimaryTheme, 'header.border.style', 'none'),
      },
      icons: {
        color: brandPrimaryTheme.header.icons.color,
        backgroundColor: brandPrimaryTheme.header.icons.backgroundColor,
      },
      backgroundGradient: {
        ...brandPrimaryTheme.header.backgroundGradient,
      },
    },
    mobileNavigation: {
      text: brandPrimaryTheme.mobileNavigation.text,
      icon: brandPrimaryTheme.mobileNavigation.icon,
      xIconBackground: brandPrimaryTheme.mobileNavigation.xIconBackground,
      xIconBackgroundHover: get(brandPrimaryTheme, 'mobileNavigation.xIconBackgroundHover', null),
      logoBarBottomBorder: brandPrimaryTheme.mobileNavigation.logoBarBottomBorder,
      navigationElementBorder: brandPrimaryTheme.mobileNavigation.navigationElementBorder,
      subNavigationTitleBackground: brandPrimaryTheme.mobileNavigation.subNavigationTitleBackground,
      subNavigationTitleColor: brandPrimaryTheme.mobileNavigation.subNavigationTitleColor,
      closeButton: {
        background: brandPrimaryTheme.mobileNavigation.xIconBackground,
        color: get(brandPrimaryTheme, 'mobileNavigation.closeButton.color', null),
      },
    },
    productPage: {
      informationBackground: brandPrimaryTheme.colors.lightGreen,
      tasteProfiles: {
        orange: brandPrimaryTheme.colors.brightOrange,
        purple: brandPrimaryTheme.colors.brightYellow,
        green: brandPrimaryTheme.colors.brightGreen,
        brown: brandPrimaryTheme.colors.brightBrown,
        blue: brandPrimaryTheme.colors.brightBlue,
      },
      mainInfo: {
        accordion: {
          color: brandPrimaryTheme.colors.darkerBrown,
          iconBackground: brandPrimaryTheme.colors.darkerBrown,
          description: {
            summaryBackground: brandPrimaryTheme.colors.darkGreen,
            summaryText: brandPrimaryTheme.colors.white,
          },
          information: {
            summaryBackground: brandPrimaryTheme.colors.lightGreen,
            summaryText: brandPrimaryTheme.colors.darkerBrown,
            detailesKey: brandPrimaryTheme.colors.darkBrown,
          },
        },
      },
      review: {
        articlePadding: theme.spacing(0, 2, 0, 2),
        background: brandPrimaryTheme.colors.lightBlue,
        divider: brandPrimaryTheme.colors.darkBlue,
      },
      labelBackground: brandPrimaryTheme.colors.darkPurple,
    },
    quote: {
      text: brandPrimaryTheme.linkColors.link,
      fontSize: '17px',
    },
    button: {
      textTransform: brandPrimaryTheme.buttonSettings.textTransform,
    },
    memoryListButton: {
      badge: { backgroundColor: brandPrimaryTheme.colors.lightPurple },
    },
    link: {
      link: brandPrimaryTheme.linkColors.link,
      active: brandPrimaryTheme.linkColors.linkPressed,
      hover: brandPrimaryTheme.linkColors.linkHover,
    },
    pageTemplates: {
      landingPage: {
        paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.landingPage.mobile.paddingTop),
        [theme.breakpoints.up(brandPrimaryTheme.breakPoints.lg)]: {
          paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.landingPage.desktop.paddingTop),
        },
      },
      otherPageTemplates: {
        paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.otherPageTemplates.mobile.paddingTop),
        [theme.breakpoints.up(brandPrimaryTheme.breakPoints.sm)]: {
          paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.otherPageTemplates.desktop.paddingTop),
        },
      },
      recipePage: {
        paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.otherPageTemplates.mobile.paddingTop),
        [theme.breakpoints.up(brandPrimaryTheme.breakPoints.sm)]: {
          paddingTop: theme.spacing(brandPrimaryTheme.pageTemplates.otherPageTemplates.desktop.paddingTop),
        },
      },
    },
    custobarSubscriptionForm: {
      background: brandPrimaryTheme.colors.darkPurple,
      hover: {
        background: brandPrimaryTheme.colors.darkGreen,
      },
      disabled: {
        background: brandPrimaryTheme.colors.lightGray,
      },
    },
    siteNotifications: {
      backgroundColor: brandPrimaryTheme.notificationColors.warningBackground,
      color: brandPrimaryTheme.notificationColors.warningContents,
      padding: theme.spacing(2.5),
      borderRadius: get(brandPrimaryTheme, 'notificationColors.borderRadius', '0'),
      margin: theme.spacing(4, 0, 4, 0),
      [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
        margin: theme.spacing(4, 0, 4, 0),
      },
    },
    monopolyPrice: {
      fontFamily: null,
      reducedPriceColor: null,
    },
  },
  accordion: {
    backgroundColor: brandPrimaryTheme.accordion.backgroundColor,
    borderRadius: brandPrimaryTheme.accordion.borderRadius,
    color: brandPrimaryTheme.accordion.color,
    flexDirection: brandPrimaryTheme.accordion.flexDirection,
    border: get(brandPrimaryTheme, 'accordion.border', 'none'),
    titleFontSize: get(brandPrimaryTheme, 'accordion.titleFontSize', '1.125rem'),
    expandIcon: {
      backgroundColor: brandPrimaryTheme.accordion.expandIcon.backgroundColor,
      hover: {
        backgroundColor: brandPrimaryTheme.accordion.expandIcon.hover.backgroundColor,
      },
    },
    padding: {
      left: brandPrimaryTheme.accordion.padding.left,
      right: brandPrimaryTheme.accordion.padding.right,
    },
    hover: {
      ...get(brandPrimaryTheme, 'accordion.hover', {}),
    },
    subtitle: {
      ...get(brandPrimaryTheme, 'accordion.subtitle', {}),
    },
    startIcon: {
      ...get(brandPrimaryTheme, 'accordion.startIcon', {}),
    },
  },
  event: {
    root: {
      ...get(brandPrimaryTheme, 'event.root', {}),
    },
    day: {
      ...get(brandPrimaryTheme, 'event.day', {}),
    },
    month: {
      ...get(brandPrimaryTheme, 'event.month', {}),
    },
    name: {
      ...get(brandPrimaryTheme, 'event.name', {}),
    },
    shortDescription: {
      ...get(brandPrimaryTheme, 'event.shortDescription', {}),
    },
    icon: {
      ...get(brandPrimaryTheme, 'event.icon', {}),
    },
  },
  languageSelector: {
    color: get(brandPrimaryTheme, 'languageSelector.color', null),
    border: {
      color: brandPrimaryTheme.languageSelector.border.color,
      width: brandPrimaryTheme.languageSelector.border.width,
    },
    margin: brandPrimaryTheme.languageSelector.margin,
  },
  table: {
    border: brandPrimaryTheme.table.border,
    borderRadius: brandPrimaryTheme.table.borderRadius,
    head: {
      backgroundColor: brandPrimaryTheme.table.head.backgroundColor,
      color: brandPrimaryTheme.table.head.color,
    },
    row: {
      even: {
        backgroundColor: brandPrimaryTheme.table.row.even.backgroundColor,
      },
      backgroundColor: brandPrimaryTheme.table.row.backgroundColor,
    },
  },
  hr: {
    ...brandPrimaryTheme.hr,
  },
  imageSpotLink: {
    innerDot: {
      ...get(brandPrimaryTheme, 'imageSpotLink.innerDot', { color: 'white' }),
    },
  },
  listElementCard: {
    content: {
      ...get(brandPrimaryTheme, 'listElementCard.content', {}),
    },
  },
  linkBlockModule: {
    center: {
      ...get(brandPrimaryTheme, 'linkBlockModule.center', {}),
    },
    imageWrapper: {
      ...get(brandPrimaryTheme, ['linkBlockModule', 'imageWrapper'], { maxWidth: '40%' }),
    },
    textWrapper: {
      ...get(brandPrimaryTheme, ['linkBlockModule', 'textWrapper'], {}),
    },
  },
  slider: {
    arrowBotton: {
      ...get(brandPrimaryTheme, 'slider.arrowBotton', {}),
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        color: brandPrimaryTheme.textColors.bodyText,
        backgroundColor: brandPrimaryTheme.backgroundColors.paper,
      },
    },
    MuiToolbar: {
      regular: {},
      dense: {
        color: brandPrimaryTheme.colors.white,
      },
    },
    MuiDrawer: {
      root: {
        width: '350px',
        flexShrink: 0,
      },
      paper: {
        width: '350px',
        color: brandPrimaryTheme.textColors.bodyText,
        backgroundColor: get(
          brandPrimaryTheme,
          'mobileNavigation.drawer.paper.backgroundColors',
          brandPrimaryTheme.backgroundColors.paper,
        ),
        [theme.breakpoints.up('500')]: {
          width: get(brandPrimaryTheme, 'mobileNavigation.drawer.paper.widthDesktop', '480px'),
        },
      },
    },
    MuiLink: {
      root: {
        color: brandPrimaryTheme.linkColors.link,
        '&:hover': {},
      },
    },
    MuiDialog: {
      paper: {
        margin: '5px',
      },
    },
    MuiButton: {
      root: {
        fontFamily: brandPrimaryTheme.textFontFamilies.fontButton,
        textTransform: brandPrimaryTheme.buttonSettings.textTransform,
        borderRadius: brandPrimaryTheme.buttonSettings.borderRadius,
        fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeButton,
        minWidth: '0',
        padding: brandPrimaryTheme.buttonSettings.buttonPaddingMobile,
        fontWeight: brandPrimaryTheme.textFontWeight.button,

        [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
          fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeButton,
          padding: brandPrimaryTheme.buttonSettings.buttonPaddingDesktop,
        },
      },
      contained: {
        '&:hover': {
          boxShadow: get(
            brandPrimaryTheme,
            'buttonSettings.hover.shadow',
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          ),
        },
      },
      sizeSmall: {
        borderRadius: brandPrimaryTheme.buttonSettings.borderRadius,
        fontSize: brandPrimaryTheme.textFontSizesMobile.fontSizeButton,
        padding: brandPrimaryTheme.buttonSettings.buttonPaddingMobile,

        [theme.breakpoints.up(brandPrimaryTheme.breakPoints.md)]: {
          fontSize: brandPrimaryTheme.textFontSizesDesktop.fontSizeButton,
          padding: brandPrimaryTheme.buttonSettings.buttonPaddingDesktop,
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        border: `2px solid ${get(
          brandPrimaryTheme,
          'primaryButtonColors.primaryButtonBorder',
          brandPrimaryTheme.primaryButtonColors.primaryButtonBackground,
        )}`,
        backgroundColor: brandPrimaryTheme.primaryButtonColors.primaryButtonBackground,
        background: brandPrimaryTheme.primaryButtonColors.primaryButtonBackgroundGradient,
        color: brandPrimaryTheme.primaryButtonColors.primaryButtonContents,
        '&:hover': {
          backgroundColor: brandPrimaryTheme.primaryButtonColors.primaryButtonHoveredBackground,
          background: 'none',
          color: get(
            brandPrimaryTheme,
            'primaryButtonColors.primaryButtonHoverContents',
            brandPrimaryTheme.primaryButtonColors.primaryButtonContents,
          ),
          border: `2px solid ${get(
            brandPrimaryTheme,
            'primaryButtonColors.primaryButtonHoverBorder',
            brandPrimaryTheme.primaryButtonColors.primaryButtonHoveredBackground,
          )}`,
          '@media (hover: none)': {
            background: 'none',
            backgroundColor: brandPrimaryTheme.primaryButtonColors.primaryButtonPressedBackground,
            border: `2px solid ${brandPrimaryTheme.primaryButtonColors.primaryButtonPressedBackground}`,
          },
        },
        '&$disabled': {
          color: brandPrimaryTheme.primaryButtonColors.primaryButtonContents,
          backgroundColor: brandPrimaryTheme.primaryButtonColors.primaryButtonDisabledBackground,
          opacity: 0.7,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${brandPrimaryTheme.outlinedButtonColors.outlinedButton}`,
        color: brandPrimaryTheme.outlinedButtonColors.outlinedButton,
        '&:hover': {
          backgroundColor: get(
            brandPrimaryTheme,
            'outlinedButtonColors.outlinedButtonHoveredBackground',
            brandPrimaryTheme.outlinedButtonColors.outlinedButtonHovered,
          ),
          border: `2px solid ${brandPrimaryTheme.outlinedButtonColors.outlinedButtonHoveredBackground}`,
          color: brandPrimaryTheme.outlinedButtonColors.outlinedButtonHovered,
          '@media (hover: none)': {
            backgroundColor: get(
              brandPrimaryTheme,
              'outlinedButtonColors.outlinedButtonHoveredBackground',
              brandPrimaryTheme.outlinedButtonColors.outlinedButtonHovered,
            ),
            color: brandPrimaryTheme.primaryButtonColors.primaryButtonContents,
          },
        },
        '&$disabled': {
          color: brandPrimaryTheme.outlinedButtonColors.outlinedButtonDisabled,
          border: `2px solid ${brandPrimaryTheme.outlinedButtonColors.outlinedButtonDisabled}`,
          opacity: 0.7,
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: brandPrimaryTheme.backgroundColors.grayLight,
      },
      separator: {
        fontSize: '1.5rem',
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 8px',
        [theme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid',
        borderRadius: brandPrimaryTheme.formColors.inputBorderRadius,
        borderColor: brandPrimaryTheme.formColors.inputBorder,
        overflow: 'hidden',
        backgroundColor: brandPrimaryTheme.formColors.inputBackground,
        '&:hover:not($disabled)': {
          borderColor: brandPrimaryTheme.formColors.inputActiveBorder,
          backgroundColor: brandPrimaryTheme.formColors.inputActiveBackground,
        },
        '& > svg': {
          color: brandPrimaryTheme.formColors.inputBorder,
        },
        '&$focused': {
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.24)',
          borderColor: brandPrimaryTheme.formColors.inputActiveBorder,
          backgroundColor: brandPrimaryTheme.formColors.inputActiveBackground,
          '&:hover:not($disabled)': {
            borderColor: brandPrimaryTheme.formColors.inputActiveBorder,
          },
        },
        '&$error:not($focused)': {
          borderColor: brandPrimaryTheme.notificationColors.errorContents,
          '&:hover:not($disabled)': {
            borderColor: brandPrimaryTheme.notificationColors.errorContents,
          },
        },
        '&$disabled': {
          backgroundColor: brandPrimaryTheme.colors.gray,
        },
      },
      adornedStart: {
        paddingLeft: '0.5rem',
      },
      adornedEnd: {
        paddingRight: '0.5rem',
      },
      input: {
        fontSize: brandPrimaryTheme.textFontSizesMobile.body1,
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
          paddingRight: '0.5rem',
        },
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        marginLeft: '0.75rem',
      },
      shrink: {
        marginTop: '-8px',
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: brandPrimaryTheme.textColors.bodyText,
        '&$error': {
          color: brandPrimaryTheme.notificationColors.errorContents,
        },
      },
      asterisk: {
        '&$error': {
          color: brandPrimaryTheme.notificationColors.errorContents,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: brandPrimaryTheme.textColors.bodyText,
        marginLeft: '0.75rem',
        '&$error': {
          color: brandPrimaryTheme.notificationColors.errorContents,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: brandPrimaryTheme.textColors.mutedText,
        backgroundColro: brandPrimaryTheme.backgroundColors.grayLight,
        '&:hover': {
          color: brandPrimaryTheme.formColors.inputActiveBorder,
          backgroundColro: brandPrimaryTheme.backgroundColors.grayLight,
        },
        '&$checked': {
          color: 'white',
          backgroundColro: brandPrimaryTheme.formColors.inputActiveBorder,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(2),
      },
    },
    MuiList: {
      root: {
        listStyle: 'square',
        '& > li::Marker': {
          color: brandPrimaryTheme.linkColors.link,
        },
      },
    },
    MuiCollapse: {
      hidden: {
        '@media print': {
          visibility: 'visible',
          height: 'auto',
        },
      },
    },
    MuiTable: {
      root: {
        border: brandPrimaryTheme.table.border,
        borderRadius: brandPrimaryTheme.table.borderRadius,
        color: 'inherit',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: brandPrimaryTheme.table.head.backgroundColor,
        color: brandPrimaryTheme.table.head.color,
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: brandPrimaryTheme.table.row.backgroundColor,
        color: 'inherit',
        '&:nth-of-type(even)': {
          backgroundColor: brandPrimaryTheme.table.row.even.backgroundColor,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: get(brandPrimaryTheme, 'table.cell.border', null),
        color: 'inherit',
        paddingBottom: 0,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'primary',
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiLink: {
      variant: 'body1',
    },
    MuiContainer: {
      disableGutters: false,
    },
    MuiBreadcrumbs: {
      separator: '›',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckBoxOutlined />,
    },
  },
});

export default primaryTheme;
