import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual, map, find } from 'lodash';
import { Accordion, AccordionSummary, AccordionDetails, List, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import Link from '../../Link';

const styles = theme => ({
  root: {
    padding: '0',
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  link: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.4px',
  },
  topLevelLink: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  linkWithoutAccordion: {
    borderBottom: 'solid 1px rgb(230, 230, 230)',
    color: theme.palette.mobileNavigation.text,
    padding: theme.spacing(1.5, 2, 1.5, 2),
  },
  subNavigationLink: {
    color: theme.palette.mobileNavigation.text,
    padding: theme.spacing(1.5, 2, 1.5, 4),
  },
  parentElementLink: {
    fontWeight: 'bold',
  },
  activeLink: {
    color: theme.palette.header.navigation.activeLink,
    fontWeight: 'bold',
  },
});

function getActiveNavigationElement(navigationElements, pathname) {
  return find(navigationElements, {
    pageReference: {
      fullPath: pathname,
    },
  });
}

function NavigationElements({ classes, className, data: navigationElements, onNavigationElementClick }) {
  const { pathname } = useLocation();

  const activeNavigationElement = useMemo(() => {
    return getActiveNavigationElement(navigationElements, pathname);
  }, [navigationElements, pathname]);

  const handleLinkClick = useCallback(
    navigationElement => () => {
      onNavigationElementClick(navigationElement);
    },
    [onNavigationElementClick],
  );

  const topLevelElements = [];
  const subNavigationItems = {};

  navigationElements.map(navigationElement => {
    const parentId = get(navigationElement, 'parentNavigationElement.id');
    if (!isEmpty(parentId)) {
      if (!isEmpty(get(subNavigationItems, parentId))) {
        subNavigationItems[parentId].push(navigationElement);
      } else {
        subNavigationItems[parentId] = [navigationElement];
      }
    } else {
      topLevelElements.push(navigationElement);
    }
  });

  return (
    <List component="nav" className={clsx(classes.root, className)}>
      {map(topLevelElements, navigationElement => {
        const navigationElementId = get(navigationElement, 'id');
        const subNavigationElements = get(navigationElement, 'subNavigationElements');
        const fullPath = get(navigationElement, 'pageReference.fullPath');

        let title = get(navigationElement, 'pageReference.menuTitle');

        if (isEmpty(title)) {
          title = get(navigationElement, 'pageReference.title');
        }
        if (isEmpty(title)) {
          title = get(navigationElement, 'title');
        }

        if (subNavigationElements) {
          return (
            <Accordion
              defaultExpanded={isEqual(
                get(activeNavigationElement, 'parentNavigationElement.id'),
                navigationElementId,
              )}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${navigationElementId}-content`}
                id={`${navigationElementId}-header`}>
                <Typography className={clsx(classes.link, classes.topLevelLink)} activeClassName={classes.activeLink}>
                  {title}
                </Typography>
              </AccordionSummary>
              {subNavigationElements && (
                <AccordionDetails className={classes.accordionDetails}>
                  {fullPath && (
                    <Link
                      to={fullPath}
                      className={clsx(classes.link, classes.subNavigationLink, classes.parentElementLink)}
                      activeClassName={classes.activeLink}
                      onClick={handleLinkClick(navigationElement)}>
                      {title}
                    </Link>
                  )}
                  {map(get(subNavigationItems, navigationElementId), subNavigationElement => {
                    return (
                      <Link
                        to={get(subNavigationElement, 'pageReference.fullPath')}
                        className={clsx(classes.link, classes.subNavigationLink)}
                        activeClassName={classes.activeLink}
                        onClick={handleLinkClick(subNavigationElement)}>
                        {get(subNavigationElement, 'title')}
                      </Link>
                    );
                  })}
                </AccordionDetails>
              )}
            </Accordion>
          );
        }

        return (
          <Link
            to={fullPath}
            className={clsx(classes.link, classes.topLevelLink, classes.linkWithoutAccordion)}
            activeClassName={classes.activeLink}
            onClick={handleLinkClick(navigationElement)}>
            {title}
          </Link>
        );
      })}
    </List>
  );
}

NavigationElements.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
  onNavigationElementClick: PropTypes.func,
};

NavigationElements.defaultProps = {
  classes: {},
  className: null,
  data: null,
  onNavigationElementClick: () => {},
};

export default withStyles(styles)(NavigationElements);
