import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { viewPage } from '../../../../utils/analyticUtils';

import MessageQueue from '../../MessageQueue';
import AdditionalNavigation from '../../../navigation/AdditionalNavigation';

function PageWrapper({ element }) {
  useEffect(() => {
    viewPage();
  });

  return (
    <>
      <MessageQueue />
      <AdditionalNavigation />
      {element}
    </>
  );
}

PageWrapper.propTypes = {
  element: PropTypes.node,
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PageWrapper.defaultProps = {
  element: null,
  props: null,
};

export default PageWrapper;
