const settings = {
  brandSite: true,
  channels: [
    {
      channel: 'Blossa SE',
      locale: 'sv-SE',
      languageCode: 'sv',
      slugs: {
        ContentfulArticlePage: 'artiklar',
      },
    },
    {
      channel: 'Blossa EN',
      locale: 'en-US',
      languageCode: 'en',
      slugs: {
        ContentfulArticlePage: 'articles',
      },
    },
  ],
  localization: {
    default: 'sv-SE',
    defaultLanguageCode: 'sv',
  },
};

export default settings;
