import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { map } from 'lodash';

import en from './en/translation.json';
import fi from './fi/translation.json';
import sv from './sv/translation.json';
import da from './da/translation.json';
import de from './de/translation.json';

import { settings } from '../sites';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fi: { translation: fi },
    sv: { translation: sv },
    da: { translation: da },
    de: { translation: de },
  },
  fallbackLng: settings.localization.defaultLanguageCode,
  supportedLngs: map(settings.channels, 'languageCode'),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
