import React from 'react';

import { Helmet } from 'react-helmet';

function DefaultFonts() {
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Playfair+Display:ital,wght@0,700;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export default DefaultFonts;
