import React from 'react';

import { get, map } from 'lodash';

import { useMessage } from '../../../context/MessageContext';

import Message from '../Message';

function MessageQueue() {
  const { messages } = useMessage();

  return map(messages, message => <Message key={get(message, 'id')} message={message} />);
}

MessageQueue.propTypes = {};

MessageQueue.defaultProps = {};

export default MessageQueue;
