/* eslint-disable dot-notation */
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { get, isNil } from 'lodash';

const KlevuSearchContext = createContext({
  updateIsInputOpen: () => {},
  isInputReady: false,
  isInputOpen: false,
});

const initialState = {
  isInputOpen: false,
};

function reducer(state, action) {
  switch (get(action, 'type')) {
    case 'updateIsInputOpen': {
      const isInputOpen = get(action, 'payload.isInputOpen');
      return { ...state, isInputOpen };
    }
    default: {
      throw new Error(`Unhandled action type: ${get(action, 'type')}`);
    }
  }
}

export function KlevuSearchProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const updateIsInputOpen = isInputOpen => {
      dispatch({ type: 'updateIsInputOpen', payload: { isInputOpen } });
    };

    return {
      isInputOpen: get(state, 'isInputOpen'),
      updateIsInputOpen,
    };
  }, [state]);

  return <KlevuSearchContext.Provider value={contextValue}>{children}</KlevuSearchContext.Provider>;
}

KlevuSearchProvider.propTypes = {
  children: PropTypes.node,
};

KlevuSearchProvider.defaultProps = {
  children: null,
};

export function useKlevuSearch() {
  const context = useContext(KlevuSearchContext);
  if (isNil(context)) {
    throw new Error('useKlevuSearch must be used within a DrawerProvider');
  }
  return context;
}
